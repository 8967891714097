import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Avatar,
    Collapse,
    CollapseProps,
    Drawer,
    Form,
    Spin,
    theme,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
type AllDetails = {
    enquiry: { name: string; id: string };
    shift: { name: string; id: string };
    fromDate: string;
    lunchTime: number;
    isLunchCal: boolean;
    batchDays: { day: number; isChecked: boolean }[];
    punchInTime: string;
    punchOutTime: string;
    recordStatus: number;
    perDayWH: any;
    id: string;
    createdBy: { userName: string; date: string };
};

const TimeScheduleHistory: React.FC<{
    enquiry: { name: string, id: string }
    setTimeHistoryDrawerClose: Function;
    timeHistoryDrawerOpen: boolean;
}> = (props) => {
    const [form] = Form.useForm();
    const onClose = () => {
        props?.setTimeHistoryDrawerClose(false);
    };
    const layout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            lg: { span: 24 },
        },
    };
    const [pageloading, setPageLoading] = useState<boolean>(false);
    const [alldetails, setAllDetails] = useState<AllDetails[]>([]);

    const Details = (record: AllDetails) => {
        let time = record.perDayWH
        var Hours = Math.floor(time / 60);
        let Minutes = time % 60;
        // console.log(`${Hours}:${Minutes}`, "reddddddc");
        return (
            <>
                <Form form={form} {...layout} colon={false}>
                    <Form.Item label="Created on" className="AlignStart mb-1 FW500" name="date">
                        <span style={{ color: "slategray" }}>{dayjs(record?.createdBy?.date).format("DD-MM-YYYY HH:MM")} By {record?.createdBy?.userName}</span>
                    </Form.Item>
                    <Form.Item label="From Date" className="AlignStart mb-1 FW500" name="fromDate">
                        <span style={{ color: "slategray" }}>{dayjs(record?.fromDate).format("DD-MM-YYYY")}</span>
                    </Form.Item>
                    <Form.Item label="Shift" className="AlignStart mb-1 FW500" name="shift">
                        <span style={{ color: "slategray" }}>{record?.shift?.name || "-"}</span>
                    </Form.Item>
                    <Form.Item label="Lunch Minutes" className="AlignStart mb-1 FW500" name="lunchTime">
                        <span style={{ color: "slategray" }}>{record?.lunchTime}</span>
                    </Form.Item>
                    <Form.Item label="Days" className="AlignStart mb-1 FW500" name="batchDays">

                        {
                            record?.batchDays?.map((item: any) => ((

                                <Avatar
                                    // size={"small"}
                                    src="M"
                                    style={{ backgroundColor: item?.isChecked === true ? "#366eff" : "#FFFF", color: "white", border: "2px solid whitesmoke" }}
                                >
                                    {item?.day === 1 && item?.isChecked === true ? "S" : item?.day === 2 && item?.isChecked === true ? "M" : item?.day === 3 && item?.isChecked === true ? "T" : item?.day === 4 && item?.isChecked === true ? "W" : item?.day === 5 && item?.isChecked === true ? "T" : item?.day === 6 && item?.isChecked === true ? "F" : item?.day === 7 && item?.isChecked === true ? "S" : item?.day === 8 && item?.isChecked === true ? "S" : ""}
                                </Avatar>

                            )))

                        }



                    </Form.Item>
                    <Form.Item label="Punch In-Out" className="AlignStart mb-1 FW500" name=""><span style={{ color: "slategray" }}>{record?.punchInTime} -{record?.punchOutTime}</span></Form.Item>


                    <Form.Item label="Per Day W/H" className="AlignStart mb-1 FW500"><span style={{ color: "slategray" }}>{Hours} Hrs {Minutes} Minutes</span></Form.Item>
                </Form>
            </>
        )
    }
    const { token } = theme.useToken();
    const panelStyle: React.CSSProperties = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: "none",
    };
    const getItems = useMemo((): CollapseProps["items"] => {
        return alldetails.map((record, index) => ({
            key: record.id || index.toString(),
            label: (<>
                Time Schedule From <span className="FW500"> {dayjs(record?.fromDate).format("DD-MM-YYYY")}</span>
            </>
            ),
            children: (
                <div>
                    <div key={index}>{Details(record)}</div>
                </div>
            ),
            style: panelStyle,
        }));
    }, [alldetails])


    const getList = async () => {
        setPageLoading(true);
        try {
            setPageLoading(true);
            let response = await HTTPSCalls.GET(
                HTTPSCalls.ENDPOINTS.GET_TIME_SCHEDULELIST_BY_ENQUIRY_ID + "?",
                { enquiryId: props?.enquiry?.id }
            );
            if (response?.result) {
                setAllDetails(
                    response?.result || [])
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setPageLoading(false);
        }
    };
    useEffect(() => {
        if (props?.timeHistoryDrawerOpen) {
            getList();
        }
    }, [props?.timeHistoryDrawerOpen]);
    return (
        <>
            <Drawer

                title={"Time Schedule History"}
                placement="right"
                width={500}
                maskClosable={false}
                onClose={onClose}
                closeIcon={false}
                open={props?.timeHistoryDrawerOpen}
                destroyOnClose={true}
                extra={
                    <CloseOutlined
                        style={{ fontSize: "16px" }}
                        className="cusror"
                        onClick={onClose}
                    />
                }
            >
                {pageloading ? (
                    <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <Spin size="small" tip="Loading..." />
                    </div>
                ) : (
                    <>
                      <Collapse
                            bordered={false}
                            defaultActiveKey={["1"]}
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                            style={{ background: token.colorBgContainer }}
                            items={getItems}
                        />
                    </>
                )}
            </Drawer>
        </>
    );
};

export default TimeScheduleHistory;
