import { Avatar, Card, Image, Row, Space, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import UploadProfile from "../../component/EnquiryCommunicationTable/UploadProfile";
import dayjs from "dayjs";
import {
  EnvironmentTwoTone,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
interface Data {
  apiData: any;
}
const StudentProfile = (props: Data) => {
  const { apiData } = props;
  return (
    <>
      <Card
        bordered
        size="small"
        style={{
          minHeight: "760px",
          boxShadow: "0 0 12px rgba(214, 223, 245, .25)",
        }}
        // style={{ width:"100%",minHeight:"700px"}}
        // style={{ height: "100vh", overflowY: "auto" }}
        title={
          <>
            <Meta
              style={{ fontSize: 12 }}
              title={
                <Space
                  size={[8, 16]}
                  direction="vertical"
                  style={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Row justify="center">
        
                          <Avatar
                          className="avatarHover"
                             style={{
                              width: "125px",
                              height: "125px",
                              borderRadius: "50%",
                              border: "4px solid rgba(9, 88, 217, 0.57)",
                            }}
                            src={apiData.candidateImage.path  }
                          />
                          <ImgCrop rotationSlider>
                          
                         
                           <Upload listType="picture-card"><UploadOutlined/> {} </Upload>
                           </ImgCrop>
                        </Row> */}

                  <Row justify="center">
                    {/* <div
                          style={{ width: "100%", backgroundColor: "#f8f9ff",padding:50 ,position:"relative"}}
                        >
                          
                        </div> */}
                    <div
                      style={{
                        width: "90%",
                        borderRadius: "12px",
                        height: "120px",
                        backgroundColor: "#f8f9ff",
                        position: "absolute",
                        top: "4%",
                        // left: "2%",
                        zIndex: 1,
                      }}
                    ></div>
                    <Avatar
                      // onClick={() => setPreviewVisible(true)}
                      className="avatarHover"
                      style={{
                        width: "125px",
                        height: "125px",
                        borderRadius: "50%",
                        border: "4px solid rgba(9, 88, 217, 0.57)",
                        zIndex: 2,
                      }}
                      // src={apiData.candidateImage.path} // Show preview image or fallback to default
                      src={
                        <Image
                          width={124}
                          height={124}
                          style={{ objectFit: "fill" }}
                          src={apiData?.candidateImage?.path}
                          alt="avatar"
                        />
                      }
                    />
                    <UploadProfile
                      apiData={apiData}
                      // afterUpload={() =>props.fetchStudent()}
                      // style={{ paddingLeft: "8px" }}
                    />{" "}
                    {/* </Upload> */}
                    {/* </ImgCrop> */}
                    {/* Display additional file information */}
                    {/* {fileInfo && (
                            <div style={{ marginTop: "16px" }}>
                              <p>
                                <b>File Name:</b> {fileInfo.name}
                              </p>
                              <p>
                                <b>File Size:</b>{" "}
                                {(fileInfo.size / 1024).toFixed(2)} KB
                              </p>
                              <p>
                                <b>File Type:</b> {fileInfo.type}
                              </p>
                              <p>
                                <b>Last Modified:</b>{" "}
                                {fileInfo.lastModifiedDate.toLocaleString()}
                              </p>
                            </div>
                          )} */}
                  </Row>
                  <Row justify="center">
                    <span
                      style={{
                        color: "#377dff",
                        textAlign: "center",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      {apiData?.name} {apiData?.middleName} {apiData?.lastName}
                    </span>
                  </Row>
                  <Row justify="center">
                    <span
                      style={{
                        color: "gray",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      Student
                    </span>
                  </Row>
                </Space>
              }
            />
          </>
        }
      >
        <Row justify="center">
          <span
            style={{
              color: "gray",
              textAlign: "center",
              fontWeight: "bold",
              paddingRight: "5px",
            }}
          >
            Student Id:
          </span>
          <span style={{ color: "black" }}>{apiData?.studentId}</span>
        </Row>
        <Card
          style={{
            marginTop: 15,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: "#f8f9ff",
          }}
        >
          <Row
            justify="center"
            style={{
              textAlign: "center",
              marginBottom: 5,
              color: "black",
            }}
          >
            Enrolled Since
          </Row>
          <Row justify="center" style={{ color: "#377dff", marginBottom: 5 }}>
            {dayjs(apiData?.enrolledDate)?.format("DD MMM YYYY")}
          </Row>
          {/* {yearsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {yearsDiff} years {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : monthsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {daysDiff} days )
                  </Row>
                )} */}
        </Card>
        <Card
          style={{
            marginTop: 15,
            marginLeft: 10,
            marginRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Row
            style={{
              textAlign: "left",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <span>
              <PhoneOutlined />
            </span>
            <span style={{ paddingLeft: 10, color: "black" }}>
              {apiData?.contactDetail?.mobileNumber}
            </span>
          </Row>
          <Row
            style={{
              textAlign: "left",
              marginTop: 10,
              marginBottom: 10,
              color: "black",
            }}
          >
            <span>
              <MailOutlined />
            </span>
            <span style={{ paddingLeft: 10 }}>
              <Tooltip placement="right" title={apiData?.contactDetail?.email}>
                <span>
                  {apiData?.contactDetail?.email?.length > 25
                    ? apiData?.contactDetail?.email.slice(0, 25) + "..."
                    : apiData?.contactDetail?.email}
                </span>
              </Tooltip>
            </span>
          </Row>
          <Row
            style={{
              textAlign: "left",
              marginTop: 10,
              marginBottom: 10,
              color: "black",
            }}
          >
            <span>
              <EnvironmentTwoTone />
            </span>
            <span style={{ paddingLeft: 10 }}>
              <Tooltip
                placement="right"
                title={apiData?.contactDetail?.currentAddress}
              >
                <span>
                  {apiData?.contactDetail?.currentAddress?.length > 25
                    ? apiData?.contactDetail?.currentAddress.slice(0, 20) +
                      "..."
                    : apiData?.contactDetail?.currentAddress}
                </span>
              </Tooltip>
            </span>
          </Row>
        </Card>
      </Card>
    </>
  );
};

export default StudentProfile;
