import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../../../Services/HTTPCalls";
// import { useQuery } from "@tanstack/react-query";
// import { MasterTypes } from "../../../../Constant/Enums";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  useGetAssessmentByDesignCourse,
  useGetStudentByAssessment,
  useGetStudentMarks,
  useSaveAllSubjectMarks,
} from "../../../../Utilities/tanStackQueries";
import { useNavigate } from "react-router-dom";
import { ApiUtility } from "../../../../Utilities/ApiUtility.axios";

function AddAllSubjectMarks() {
  const [loading, setLoading] = useState<boolean>(false);
  const [departMentData, setDepartMentData] = useState();
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  // const [subjectsData, setSubjectsData] = useState([]);
  // const [allStudentDetail, setAllStudentDetail] = useState<any>();
  const [assessmentId, setAssessmentId] = useState<any>("");
  const [trigger, setTrigger] = React.useState<number>(0);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  //const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  // const [pagingItems, setPagingItems] = useState<{
  //   totalRecords: number;
  //   currentPage: number;
  //   pageSize: number;
  // }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  //   const [start, setStart] = useState<any>(0);
  //   const [length, setLength] = useState<any>(15);
  const [form] = Form.useForm();
  const [inputForm] = Form.useForm();
  const navigate = useNavigate();
  const saveAllSubjectMarksMutation = useSaveAllSubjectMarks();
  // const {
  //   data: AssessmentCategory,
  //   isLoading: categoryLoading,
  //   refetch,
  // } = useQuery({
  //   queryKey: ["Category"],
  //   queryFn: async () => {
  //     try {
  //       const res = await HTTPSCalls.GET(
  //         HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
  //         {
  //           masterType: MasterTypes.AssessmentCategory,
  //           // start: start,
  //           // length: length,
  //         }
  //       );
  //       if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
  //         // setPagingItems((p) => {
  //         //   p.totalRecords = res?.result?.totalRecords;
  //         //   return p;
  //         // });
  //         console.log(res?.result?.items, "assessmentCategory");
  //         return res?.result?.items.map((item: any) => ({
  //           ...item,
  //           key: item.id,
  //         })); // Add key prop to each item
  //       } else {
  //         console.log("error");
  //         return []; // return an empty array when API call fails or no data
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       return []; // return an empty array when API call fails
  //     }
  //   },
  // });

  // useEffect(() => {
  //   setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
  //   setLength(pagingItems?.pageSize);
  // }, [pagingItems]);

  const getDesignCourseData = async (formValues: any) => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_MASTER_DETAILS + "?",
        {},
        {
          departMentId: formValues?.department?.value || "",
          courseId: formValues?.course?.value || "",
          batchId: formValues?.batch?.value || "",
          semesterId: formValues?.semester?.value || "",
          sectionId: formValues?.section?.value || "",
        }
      );
      if (res?.result) {
        // console.log(res?.result, "resresult");
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);
        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setCourseData(courseData);

        let batchData = res?.result?.batches?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);
        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);
        let sectionsData = res?.result?.sections?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
        // let subjectsData = res?.result?.subjects?.map((itm: any) => {
        //   return {
        //     value: itm?.id,
        //     label: itm?.name,
        //   };
        // });
        // setSubjectsData(subjectsData);
      }
    } catch (error) {
      console.error("something went wrong", error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    const formValues = form.getFieldsValue();
    getDesignCourseData(formValues);
  }, [trigger]);

  const {
    data: assessmentData,
    isLoading: assessmentLoading,
    // refetch: assessmentRefetch,
  } = useGetAssessmentByDesignCourse({
    formData: form.getFieldsValue(),
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    // refetch: studentRefetch,
  } = useGetStudentByAssessment({
    assessmentId,
  });

  const {
    data: studentMarksDetail,
    isLoading: studentMarksLoading,
    refetch: studentMarksRefetch,
  } = useGetStudentMarks({
    assessmentId,
  });

  useEffect(() => {
    if (studentMarksDetail) {
      let setMarksVal = studentMarksDetail?.result?.reduce(
        (acc: any, e: any) => {
          e.students.forEach((student: any) => {
            acc[`${student.id}_${e.subject.id}`] = student?.obtainedMarks;
          });
          return acc;
        },
        {}
      );

      let setPresentVal = studentMarksDetail?.result?.reduce(
        (acc: any, e: any) => {
          e.students.forEach((student: any) => {
            acc[`${student.id}_${e.subject.id}`] = student?.isPresent;
            //acc.push(student?.isPresent);
          });
          return acc;
        },
        []
      );

      console.log(setPresentVal, "setPresentValsdds", setMarksVal);
      inputForm.resetFields();
      if (Object.keys(setMarksVal).length > 0) {
        Object.entries(setPresentVal).forEach(([key, isPresent]) => {
          if (isPresent) {
            const obtainedMarks = setMarksVal[key];
            inputForm.setFieldValue(key, obtainedMarks || 0);
          }
        });
      }

      // if (setMarksVal ) {
      //   inputForm.setFieldsValue(e);
      // }
    }
  }, [studentMarksDetail]);

  let columns: TableProps<any>["columns"] = [
    {
      title: "Student Id",
      dataIndex: "studentId",
      key: "studentId",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text: any, record: any) => (
        text
      ),
    },
    // {
    //   title: "Present",
    //   dataIndex: "present",
    //   key: "present",
    //   width: "5%",
    //   render: (text: any, record: any) => (
    //     <Form form={inputForm}>
    //       <Form.Item name={`present_${record.id}`} valuePropName="checked">
    //         <Checkbox />
    //       </Form.Item>
    //     </Form>
    //   ),
    // }
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "right",
    //   render: (_, record:any) => (
    //     <Row justify="end">
    //     {record.recordStatus === RecordStatusType.Deleted ? (
    //       <UndoOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd", cursor: "pointer" }}
    //         // onClick={() =>{
    //         //   updateStatusMution.mutate({id:record?.id, restore:"restore"},{
    //         //     onSuccess:()=>refetch()
    //         //   })}

    //         // }
    //       />
    //     ) : (
    //       <DeleteOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd", cursor: "pointer" }}
    //         // onClick={() => updateStatusMution.mutate({id:record?.id, restore:"delete"},{
    //         //   onSuccess:()=>refetch()
    //         // })}
    //       />
    //     )}
    //   </Row>
    //   ),
    // },
  ];

  if (studentData?.result && studentData.result.length > 0) {
    const subjectColumns = studentData.result?.[0].subject.map(
      (subjectData: any) => ({
        title: ` ${subjectData.subject.name} (${subjectData?.maxMarks})`,
        dataIndex: subjectData.subject.id,
        key: subjectData.subject.id,
        render: (text: any, record: any) => (
          <>
            <Form
              form={inputForm}
              style={{
                margin: 0,
                padding: 0,
                height: "100%",
              }}
            >
              <Form.Item
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  margin: 0,
                }}
                name={`${record.id}_${subjectData.subject.id}`}
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        //!value ||
                        value > subjectData?.maxMarks
                      ) {
                        return Promise.reject(
                          new Error(
                            `Value cannot exceed Max Marks ${subjectData?.maxMarks}`
                          )
                        );
                      } else if (value < 0) {
                        return Promise.reject(
                          new Error(`Value should be whole number`)
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  size="small"
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    inputForm.validateFields([
                      `${record.studentId}_${subjectData.subject.id}`,
                    ]);
                  }}
                />
              </Form.Item>
            </Form>
          </>
        ),
      })
    );

    columns.push(...subjectColumns);
  }

  const handleAllSubjectMarks = async () => {
    if (studentData) {
      inputForm.validateFields().then((e) => {
        let studentArray = studentData.result.map((student: any) => {
          const subjects = student.subject.map((subjectData: any) => ({
            subjectId: subjectData.subject.id,
            subjectName: subjectData.subject.name,
            maxMarks: subjectData.maxMarks,
            isPresent:
              e[`${student.id}_${subjectData.subject.id}`] === "" ||
              e[`${student.id}_${subjectData.subject.id}`] === undefined ||
              e[`${student.id}_${subjectData.subject.id}`] === null
                ? false
                : true,
            obtainedMarks: e[`${student.id}_${subjectData.subject.id}`] || 0,
            studentId: student.studentId,
            id: student.id,
            name: student.name,
          }));
          return subjects;
          // conso
          // return {
          //   subjectsId: student,
          // };
        });
        studentArray = studentArray?.flatMap((e: any) => e);
        //setAllStudentDetail(studentArray);
        saveAllSubjectMarksMutation.mutate(
          {
            assessmentId: assessmentId,
            student: studentArray,
          },
          {
            onSuccess: () => {
              studentMarksRefetch();
            },
          }
        );
        // console.log(studentData.result, "Student Array:", studentArray);
        console.log(e, "Student Array feawrewr:");
      });
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    switch (field) {
      case "department":
        form.resetFields([
          "course",
          "batch",
          "section",
          "semester",
          "assessment",
        ]);
        break;
      case "course":
        form.resetFields(["batch", "section", "semester", "assessment"]);
        break;
      case "batch":
        form.resetFields(["section", "semester", "assessment"]);
        break;
      case "semester":
        form.resetFields(["section", "assessment"]);
        break;
      case "section":
        form.resetFields(["assessment"]);
        break;
      default:
        break;
    }
    setAssessmentId("");
    setTrigger((prev) => prev + 1);
  };
  // const gap = "middle";
  const handleDownloadCsv = async () => {
    try {
      setCsvLoading(true);
      await ApiUtility.downloadFile(
        HTTPSCalls.ENDPOINTS.DOWNLOAD_SUBJECT_MARKS_CSV + "?",
        {
          assessmentId: assessmentId,
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setCsvLoading(false);
    }
  };
  return (
    <>
      <ArrowLeftOutlined onClick={() => navigate(-1)} />
      <Card className="roundedCornerSmall mt-2">
        <Form form={form}>
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="department"
                label="Department"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={departMentData}
                  onChange={(value) => handleFieldChange("department", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="course"
                label="Course"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={courseData}
                  onChange={(value) => handleFieldChange("course", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="batch"
                label="Batch"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={batchData}
                  onChange={(value) => handleFieldChange("batch", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="semester"
                label="Semester"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={semesterData}
                  onChange={(value) => handleFieldChange("semester", value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="section"
                label="Section"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={sectionsData}
                  onChange={(value) => handleFieldChange("section", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="assessment"
                label="Assessment"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className="AlignStart"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  style={{ width: "100%" }}
                  labelInValue
                  options={assessmentData}
                  loading={assessmentLoading}
                  onSelect={(val) => {
                    // console.log("assessmentSelect", val);
                    setAssessmentId(val.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col span={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={studentData?.result}
              style={{ marginTop: 14 }}
              footer={() => (
                <Row justify={"end"} gutter={12}>
                  <Col>
                    <Button
                      loading={csvLoading}
                      icon={<DownloadOutlined />}
                      onClick={handleDownloadCsv}
                      disabled={studentData?.result?.length > 0 ? false : true}
                    >
                      Export
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleAllSubjectMarks}
                      disabled={!studentData}
                      loading={saveAllSubjectMarksMutation.isPending}
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              )}
              loading={{
                spinning: studentLoading || studentMarksLoading,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              pagination={false}
              // pagination={{
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              //   selectPrefixCls: "custom-table-select",
              //   current: pagingItems.currentPage,
              //   pageSize: pagingItems.pageSize,
              //   showSizeChanger: true,
              //   total: pagingItems.totalRecords,
              //   pageSizeOptions: ["15", "25", "50", "100"],
              // }}
              // onChange={(paging, filter, sort: any) => {
              //   paging &&
              //     setPagingItems({
              //       ...pagingItems,
              //       currentPage: paging?.current ?? 1,
              //       pageSize: paging?.pageSize ?? 15,
              //     });
              // }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AddAllSubjectMarks;
