import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  TimePicker,
  Checkbox,
  Col,
  message,
  Select,
  Tooltip,
  Card,
  DatePicker,
  Divider,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useQuery, useMutation } from "@tanstack/react-query";
import { transpileModule } from "typescript";
import Item from "antd/es/list/Item";
import moment from "moment";
import { adminContext } from "../../Common/PageRoute";
import { MasterTypes } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import { Option } from "antd/es/mentions";
import { IdName } from "../../Types";
import { queryClient } from "../../App";

interface IBatchType {
  key: string;
  checkbox: boolean;
}
const AddEditTimeSchedule: React.FC<{
  SetDrawerClose: Function;
  openDrawer: boolean;
  enquiryIdName:IdName
  recordData?: any;
  setRecordData?:any;
}> = (props) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const batchDaysData = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const columnsNonBatch: ColumnsType<IBatchType> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.mon}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, mon: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.tue}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, tue: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.wed}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, wed: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.thu}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, thu: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.fri}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, fri: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sat}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sat: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sun}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sun: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
  ];
  const [lunchMinInput, setLunchMinInput] = useState<any>({
    lunchMin: 0,
    isLMCalc: false,
  });
  const [shifts, setShifts]: any = useState([]);
  const [overallHours, setOverAllHours] = useState("");
  const [workingHours, setWorkingHours] = useState<any>(0);
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const {SetDrawerClose, openDrawer,enquiryIdName,recordData ,setRecordData} =
    props;
  const [loading, setLoading] = useState(false);
  const [disabledByShift, setDisabledByShift] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [batchDays, setBatchDays] = useState<any>({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });

  const onClose = () => {
    SetDrawerClose(false);
    form.resetFields();
    setDisabledByShift(false);
    setBatchDays({
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    })
    setLunchMinInput({ lunchMin: 0, isLMCalc: false });
    setOverAllHours("");
    setWorkingHours(0);
    setRecordData();
  };

  const getShitList = async (start: number, length: number) => {
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.POST_MASTER + "?", {
        masterType: MasterTypes.Shift,
        start: start,
        length: length,
        status: "active",
        sortCol: "createdBy.Date",
      });
      if (res?.status) {
        setShifts(res?.result?.items);
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getShitById = async (id: { label: string; value: string },isNull:boolean=false) => {
    try {
      if (id?.value !== "custom"||isNull  ) {
        console.log(shifts,"id", id);
        let filterShift =isNull?recordData: shifts?.filter(
          (item: any) => item?.id === id?.value
        )?.[0] 
        setLoading(true);
        console.log("res", filterShift);
        setDisabledByShift(!isNull);
        form.setFieldsValue({
          punchInTime: dayjs(filterShift.punchInTime, "HH:mm"),
          punchOutTime: dayjs(filterShift.punchOutTime, "HH:mm"),
          lunchTime: filterShift.lunchTime,
        });

        setBatchDays({
          sun: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 1 && d?.isChecked;
          }),
          mon: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 2 && d?.isChecked;
          }),
          tue: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 3 && d?.isChecked;
          }),
          wed: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 4 && d?.isChecked;
          }),
          thu: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 5 && d?.isChecked;
          }),
          fri: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 6 && d?.isChecked;
          }),
          sat: filterShift?.batchDays?.some((d: any) => {
            return d?.day === 7 && d?.isChecked;
          }),
        });
        setLunchMinInput({
          lunchMin: filterShift?.lunchTime,
          isLMCalc: filterShift?.isLunchCal,
        });
        
      } else {
        setDisabledByShift(false);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getShitList(0, 10000);
  }, []);

  useEffect(() => {
    if (recordData &&shifts?.length>0) {
      console.log("recordData", recordData);
      let isShiftEmpty =recordData?.shift ? false:true;
      form.setFieldsValue({
        fromDate: dayjs(recordData?.fromDate),
        shift:{
          label:recordData?.shift?.name||"Custom Shift",
          value:recordData?.shift?.id||"custom"
        }
      })
      getShitById({
        label:recordData?.shift?.name,
        value:recordData?.shift?.id
      },isShiftEmpty);
      handleFormChange({}, {
        punchInTime: dayjs(recordData?.punchInTime, "HH:mm"),
        punchOutTime: dayjs(recordData?.punchOutTime, "HH:mm"),
      },   {lunch: recordData?.lunchTime})
    }
  }, [recordData]);
  
  

  const saveUpdateShiftDetail = () => {
    let weekdays = [
      { day: 1, isChecked: batchDays.sun },
      { day: 2, isChecked: batchDays.mon },
      { day: 3, isChecked: batchDays.tue },
      { day: 4, isChecked: batchDays.wed },
      { day: 5, isChecked: batchDays.thu },
      { day: 6, isChecked: batchDays.fri },
      { day: 7, isChecked: batchDays.sat },
    ];
    let checkDays = weekdays.some((item: any) => item.isChecked === true);
    if (!checkDays) {
      openNotification("error", "Please select Work days");
      form.validateFields();
    } else {
      form.validateFields().then(async (val) => {
        setBtnLoading(true);
        let ShiftDetail = {
          enquiry: enquiryIdName,
          ...(val?.shift.value!=="custom"&&{ shift:{
            id: val?.shift?.value,
            name: val?.shift?.label
          }}),
          ...(recordData?.id && {id:recordData?.id}),
          fromDate: val?.fromDate?.format("MM-DD-YYYY"),
          punchInTime: val?.punchInTime?.format("HH:mm"),
          punchOutTime: val?.punchOutTime?.format("HH:mm"),
          batchDays: weekdays,
          isLunchCal: lunchMinInput?.isLMCalc,
          lunchTime: lunchMinInput?.lunchMin,
        };

        HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.SAVE_TIME_SCHEDULE,
          {},
          {
            ...ShiftDetail,
          }
        ).then((res: IApiResponse) => {
          console.log("Post res = ", res);
          if (res?.status) {
            onClose();
            openNotification(
              "success","Shift applied successfully"
            );
                    queryClient.invalidateQueries({queryKey:["getTimeScheduleByEnquiryId"]});
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
        console.log(ShiftDetail, "BatchValues");
      });
    
    }
  };


  const calculateWorkingHours = (
    punchInTime: any,
    punchOutTime: any,
    lunchMinutes: number = 0
  ) => {
    if (punchInTime && punchOutTime) {
      const duration = moment.duration(punchOutTime.diff(punchInTime));
      let totalMinutes = duration.asMinutes() - lunchMinutes;
      if (totalMinutes < 0) totalMinutes = 0;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} hrs ${minutes}`;
    }
    return "";
  };

  const handleFormChange = (changedValues: any, allValues: any,lunchOp?:any) => {
    const { punchInTime, punchOutTime } = allValues;
    var lunch = form.getFieldValue("lunchTime")||lunchOp?.lunch;
    if (punchInTime && punchOutTime) {
      const overallHours = calculateWorkingHours(punchInTime, punchOutTime);
      const workingHours = calculateWorkingHours(punchInTime, punchOutTime, lunch);
      setOverAllHours(overallHours);
      setWorkingHours(workingHours);
    } else {
      setOverAllHours("");
    }
  };

  return (
    <Drawer
      title={recordData?.id ? "Edit Time Schedule" : "Add Time Schedule"}
      // title={"Add Time Schedule"}
      placement="right"
      width={500}
      maskClosable={false}
      onClose={onClose}
      closeIcon={false}
      open={openDrawer}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="cusror"
          onClick={onClose}
        />
      }
      footer={
        <Row justify="end">
          <Button
            onClick={onClose}
            disabled={btnLoading}
            className="ttp-btn-light btn-m-sm"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="btn-m-sm"
            loading={btnLoading}
            onClick={saveUpdateShiftDetail}
            // onClick={()=>Mutation.mutate()}
          >
            {/* {props?.recordData?.id ? "Update" : "Save"} */}
            Save
          </Button>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <Form
          form={form}
          name="batch-form"
          className="DrawerPadding"
          colon={false}
          onValuesChange={handleFormChange}
          autoComplete="off"
          style={{ padding: 16 }}
          {...formItemLayout}
          labelAlign="left"
          requiredMark={false}
          disabled={disabledByShift}
          initialValues={{ fromDate: dayjs() }}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="From Date" name="fromDate">
                <DatePicker format={"DD/MM/YYYY"} disabled={false} allowClear={false}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Shift"
                name="shift"
                initialValue={{ label: "Custom Shift", value: "custom" }}
                labelAlign="left"
                className="form-item-input"
              >
                <Select
                  placeholder="Select Shift"
                  labelInValue
                  disabled={false}
                  onSelect={(value) => {
                    getShitById(value);
                    handleFormChange({ shift: value }, form.getFieldsValue())
                  }}
                  autoFocus={false}
                  dropdownRender={(menu) => {
                    return (
                      <>
                        {menu}
                        {/* {
                                                <>
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Row gutter={8} className="px-3 pb-1">
                                                        <Button style={{ borderRadius: 5, color: '#1890FF' }}
                                                            size='small' title="Add new shift"
                                                            // onClick={() => setIsNewShiftDrawer(true)}
                                                            >
                                                            + Shift
                                                        </Button>
                                                    </Row>
                                                </>
                                            } */}
                      </>
                    );
                  }}
                >
                  <Option value="custom">Custom Shift</Option>
                  {shifts?.map((item: any, index: number) => (
                    <Option value={item?.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Punch In"
                name="punchInTime"
                rules={[
                  { required: true, message: "Please select time!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const punchOutTime = getFieldValue("punchOutTime");
                      if (!value || !punchOutTime || value.isBefore(punchOutTime)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "In Time should not be greater than Out Time!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <TimePicker format="h:mm:A" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Punch Out"
                name="punchOutTime"
                rules={[
                  { required: true, message: "Please select time!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const punchInTime = getFieldValue("punchInTime");
                      if (!value || !punchInTime || punchInTime.isBefore(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Out Time should be greater than In Time!")
                      );
                    },
                  }),
                ]}
              >
                <TimePicker format="h:mm:A" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="fw500">Work Days</Col>
            <Col span={24} className="mt-1 CitTable">
              <Table
                bordered={true}
                columns={columnsNonBatch}
                pagination={{ hideOnSinglePage: true }}
                dataSource={batchDaysData}
                size={"small"}
                scroll={{ x: 400 }}
              />
            </Col>
          </Row>
          <Form.Item label="Lunch Time" name="lunchTime">
            <Input
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              suffix={
                <>
                  {" "}
                  |{" "}
                  <Checkbox
                    checked={lunchMinInput?.isLMCalc}
                    className="ms-2"
                    onChange={(e: any) =>
                      setLunchMinInput({
                        ...lunchMinInput,
                        isLMCalc: e.target.checked,
                      })
                    }
                  >
                    Calculation in Lunch Min
                  </Checkbox>
                  <Tooltip
                    color="#e6f4ff"
                    placement="topLeft"
                    overlayInnerStyle={{ fontSize: 13, color: "black" }}
                    title={
                      <Row>
                        <Col span={24} className="fs13">
                          {" "}
                          <CheckOutlined
                            style={{ fontSize: 13, color: "green" }}
                            className="me-2"
                          />
                          Tick checkbox (The lunch break duration will be
                          deducted, regardless of whether they use it or not.)
                        </Col>
                        <Col span={24} className="fs13">
                          {" "}
                          <CloseOutlined
                            style={{ fontSize: 13, color: "red" }}
                            className="me-2"
                          />
                          Untick checkbox (Deduct only the time that employees
                          actually spend on their lunch break.)
                        </Col>
                      </Row>
                    }
                  >
                    <InfoCircleOutlined
                      style={{ color: "#a5abdd" }}
                      className="cursor"
                    />
                  </Tooltip>
                </>
              }
              // disabled={disabled}
              value={lunchMinInput?.lunchMin}
              maxLength={3}
              className="borderLessInput"
              onChange={(e: any) => {
                setLunchMinInput({
                  ...lunchMinInput,
                  lunchMin: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
        <Card
          style={{ backgroundColor: "#fbfcff", margin: "60px 40px 0px 40px" }}
          bodyStyle={{ padding: 20 }}
          title={
            <p style={{ color: "#1890ff" }}>
              Time Schedule Summary <HistoryOutlined />
            </p>
          }
          size="small"
        >
          <Row
            className="fw500 p-2 roundedCornerSmall"
            style={{ border: "1px solid #e4e7ef", backgroundColor: "#fff" }}
          >
            <Col span={24}>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Working Hours
                </Col>
                <Col span={12}> {workingHours || 0}mins </Col>
                {/* <Col span={12}>{getWorkingDuration(times?.in, times?.out, lunchMinInput?.lunchMin)} Hrs</Col> */}
              </Row>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Lunch Minutes
                </Col>
                <Col className="border-bottom">
                  {lunchMinInput?.lunchMin ?? 0} mins
                </Col>
              </Row>
              <Row className="ms-3 me-4 mb-2">
                <Col span={12} style={{ color: "#00000073" }}>
                  Overall Hours
                </Col>
                <Col span={12}> {overallHours || 0} mins </Col>
                {/* <Col span={12} >{getOverallDuration(times?.in, times?.out)} Hrs</Col> */}
              </Row>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Drawer>
  );
};
export default AddEditTimeSchedule;
