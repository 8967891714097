import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Table, { ColumnsType } from "antd/es/table";
import React from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";

const JoinDetails = () => {
  const [form] = useForm();
  const batchDaysData = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const columnsNonBatch: ColumnsType<any> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => <Checkbox></Checkbox>,
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => <Checkbox></Checkbox>,
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => <Checkbox></Checkbox>,
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => <Checkbox></Checkbox>,
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
        // checked={batchDays.fri}
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
        // checked={batchDays.sat}
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => <Checkbox></Checkbox>,
      align: "center",
    },
  ];
  return (
    <>
      <Row gutter={18}>
        <Col span={12}>
          <Card
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              border: "1px solid lightgray",
            }}
            title={
              <Row>
                <Col span={20}></Col>
                <Col span={4} style={{ textAlign: "end" }}>
                  <BsThreeDotsVertical className="cursor" />
                </Col>
              </Row>
            }
          >
            <Space size={70}>
              <ul style={{ listStyleType: "none", lineHeight: 3 }}>
                <li>Join Date</li>
                <li>Enrolled Date</li>
              </ul>
              <ul style={{ listStyleType: "none", lineHeight: 3 }}>
                <li>----</li>
                <li>----</li>
              </ul>
            </Space>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <Row>
                <Col span={20}>
                  <Button type="primary" size="small" icon={<PlusOutlined />}>
                    Time Schedule
                  </Button>
                </Col>
                <Col span={4} style={{ textAlign: "end" }}>
                  <BsThreeDotsVertical className="cursor" />
                </Col>
              </Row>
            }
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              border: "1px solid lightgray",
            }}
          >
            <Row className="mb-3">
              <Col span={10}>From Date</Col>
              <Col span={14}>--</Col>
            </Row>

            <Row className="mb-3">
              <Col span={10}>Shift</Col>
              <Col span={14}>-- </Col>
            </Row>

            <Row className="mb-3">
              <Col span={10}>Lunch Minutes</Col>
              <Col span={14}>
                <span>--</span>
                <Checkbox disabled className="ms-2">
                  Calculation in Lunch Min
                </Checkbox>
                <Tooltip
                  color="#e6f4ff"
                  placement="topLeft"
                  overlayInnerStyle={{ fontSize: 13, color: "black" }}
                  title={
                    <Row>
                      <Col span={24} className="fs13">
                        {" "}
                        <CheckOutlined
                          style={{ fontSize: 13, color: "green" }}
                          className="me-2"
                        />
                        Tick checkbox (The lunch break duration will be
                        deducted, regardless of whether they use it or not.)
                      </Col>
                      <Col span={24} className="fs13">
                        {" "}
                        <CloseOutlined
                          style={{ fontSize: 13, color: "red" }}
                          className="me-2"
                        />
                        Untick checkbox (Deduct only the time that employees
                        actually spend on their lunch break.)
                      </Col>
                    </Row>
                  }
                >
                  <InfoCircleOutlined
                    style={{ color: "#a5abdd" }}
                    className="cursor"
                  />
                </Tooltip>
              </Col>
            </Row>

            <Typography.Title
              style={{ fontSize: "14px" }}
              className="mt-3"
              level={5}
            >
              Work Days
            </Typography.Title>
            <Col span={24} className="mt-1 ">
              <Table
                bordered={true}
                columns={columnsNonBatch}
                pagination={{ hideOnSinglePage: true }}
                dataSource={batchDaysData}
                size={"small"}
                scroll={{ x: 400 }}
              />
            </Col>
            <Row className="mt-2">
              <Col xs={24} md={12} xl={12}>
                <Row justify="space-between" className="my-2">
                  <Col>Punch In</Col>
                  <Col span={8}>---</Col>
                </Row>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Row justify="space-between" className="my-2">
                  <Col>Punch Out</Col>
                  <Col span={8}>--</Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={24} md={12} xl={12}>
                <Row justify="space-between" className="my-2">
                  <Col>
                    Marginal Overtime
                    <Tooltip
                      className="ms-1 cursor"
                      color="#e6f4ff"
                      placement="rightTop"
                      title={
                        <span style={{ color: "black", fontSize: 13 }}>
                          {" "}
                          Overtime begins when an employee works more than the
                          specified number of overtime minutes.{" "}
                        </span>
                      }
                    >
                      <InfoCircleOutlined style={{ color: "#a5abdd" }} />
                    </Tooltip>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <span style={{ color: "#00000066" }}>(min)</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Row justify="space-between" className="my-2">
                  <Col>
                    Marginal Short Hour
                    <Tooltip
                      className="ms-1 cursor"
                      color="#e6f4ff"
                      placement="rightTop"
                      title={
                        <span style={{ color: "black", fontSize: 13 }}>
                          {" "}
                          Short hour is applied when an employee's short minutes
                          are greater than the specified short margin.{" "}
                        </span>
                      }
                    >
                      <InfoCircleOutlined style={{ color: "#a5abdd" }} />
                    </Tooltip>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <span style={{ color: "#00000066" }}>(min)</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              style={{ background: "#dee2e638" }}
              className="px-3 py-2 mb-3 mt-3 roundedCornerSmall"
            >
              <Col className="fw600 mb-2">
                Time Schedule Summary <HistoryOutlined />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Row className="mb-1 color" justify="center">
                      {" "}
                      Hrs
                    </Row>
                    <Row className="mb-1 fw500" justify="center">
                      Working Hours
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row className="mb-1 color" justify="center">
                      {" "}
                      Minutes
                    </Row>
                    <Row className="mb-1 fw500" justify="center">
                      Lunch Minutes
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row className="mb-1 color" justify="center">
                      {" "}
                      Hrs
                    </Row>
                    <Row className="mb-1 fw500" justify="center">
                      Overall Hours
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default JoinDetails;
