import { Button, Col, Form, message, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
interface courseDataInterface {
  apiData: any;
}
const CourseData = (props: courseDataInterface) => {
  const [form] = useForm();
  const [departMentData, setDepartMentData] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>([]);
  const [batchData, setBatchData] = useState<any>([]);
  const [semesterData, setSemesterData] = useState<any>([]);
  const [sectionsData, setSectionsData] = useState<any>([]);
  const [departmentId, setDepartmentId] = useState<string>("");
  const [courseId, setCourseId] = useState<string>("");
  const [batchId, setBatchId] = useState<string>("");
  const [semesterId, setSemesterId] = useState<string>("");
  const [sectionId, setSectionId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  // const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const [isEdit, setIsEdit] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS + "?",
        {},
        {
          departMentId: departmentId,
          courseId,
          batchId,
          semesterId,
        }
      );
      if (res?.result) {
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);

        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });

        setCourseData(courseData);

        let batchData = res?.result?.batch?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);

        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);

        let sectionsData = res?.result?.section?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      department: props?.apiData?.department
        ? setValueLabel(props?.apiData?.department)
        : null,
      course: props?.apiData?.course
        ? setValueLabel(props?.apiData?.course)
        : null,
      batch: props?.apiData?.batch
        ? setValueLabel(props?.apiData?.batch)
        : null,
      semester: props?.apiData?.semester
        ? setValueLabel(props?.apiData?.semester)
        : null,
      section: props?.apiData?.section
        ? setValueLabel(props?.apiData?.section)
        : null,
    });
    props?.apiData?.department?.id && setDepartmentId(props?.apiData?.department.id);
    props?.apiData?.course?.id && setCourseId(props?.apiData?.course.id);
    props?.apiData?.batch?.id && setBatchId(props?.apiData?.batch.id);
    props?.apiData?.semester?.id && setSemesterId(props?.apiData?.semester.id);
    props?.apiData?.section?.id && setSectionId(props?.apiData?.section.id);

  }, [props?.apiData]);

  const setValueLabel = (data: { id: string; name: string }) => {
    return {
      value: data?.id,
      label: data?.name,
    };
  };
  useEffect(() => {
    getData();
  }, [departmentId, courseId, batchId, semesterId]);

  const handleEditCourseDetails = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      const payload = {
        id: props?.apiData?.id,
        department: {
          id: formData?.department?.value,
          name: formData?.department?.label,
        },
        course: {
          id: formData?.course?.value,
          name: formData?.course?.label,
        },
        batch: {
          id: formData?.batch?.value,
          name: formData?.batch?.label,
        },
        semester: {
          id: formData?.semester?.value,
          name: formData?.semester?.label,
        },
        section: {
          id: formData?.section?.value,
          name: formData?.section?.label,
        },
      };

      const response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.UPDATE_COURSE_DETAILS + "?",
        {},
        payload
      );

      if (response?.status) {
        message.success("Course details updated successfully.");
      } else {
        message.error("Failed to update course details.");
      }
    } catch (error) {
      message.error("Something went wrong.");
      console.error("Error updating course details:", error);
    } finally {
      setLoading(false);
      setComponentDisabled(true);
      setIsEdit(false);
    }
  };

  return (
    <>
      <Row justify={"end"}>
        {!isEdit ? (
          <>
            {/* <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            >
              Edit Details
            </Checkbox> */}
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setIsEdit(true);
                setComponentDisabled(false);
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          <Button
            type="link"
            icon={<CloseOutlined />}
            onClick={() => {
              setIsEdit(false);
              setComponentDisabled(true);
            }}
          >
            Close
          </Button>
        )}
      </Row>
      <Form
        form={form}
        disabled={componentDisabled}
        onFinish={handleEditCourseDetails}
        labelCol={{ xs: 10, sm: 8 }}
        wrapperCol={{ xs: 14, sm: 15 }}
        requiredMark={false}
      >
        <Row gutter={12}>
          <Col span={7}>
            <Form.Item
              name={"department"}
              label="Department"
              rules={[{ required: true }]}
              className="AlignStart"
            >
              <Select
                className="customSelector"
                loading={loading}
                labelInValue={true}
                options={departMentData}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setDepartmentId(e.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="course"
              label="Course"
              rules={[{ required: true }]}
                 className="AlignStart"
            >
              <Select
                className="customSelector"
                loading={loading}
                labelInValue={true}
                options={courseData}
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setCourseId(e.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="batch"
              label="Batch/Session"
              rules={[{ required: true }]}
                 className="AlignStart"
            >
              <Select
                className="customSelector"
                loading={loading}
                labelInValue={true}
                options={batchData}
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setBatchId(e.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={7}>
            <Form.Item
              name="semester"
              label="Semesters"
              rules={[{ required: true }]}
                 className="AlignStart"
            >
              <Select
                className="customSelector"
                loading={loading}
                labelInValue={true}
                options={semesterData}
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setSemesterId(e.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="section"
              label="Section"
              rules={[{ required: true }]}
                 className="AlignStart"
            >
              <Select
                className="customSelector"
                loading={loading}
                labelInValue={true}
                options={sectionsData}
                style={{ width: "100%" }}
                onChange={(e: any) => {
                  setSectionId(e.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button
                onClick={() => form.submit()}
                // type="submit"
                className="ant-btn ant-btn-primary"
                disabled={loading || componentDisabled}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CourseData;
