import { ApiUtility } from "../Utilities/ApiUtility.axios";

// import {}  from "process"
class HTTPSCalls {
  static ENDPOINTS = {
    ENQUIRY_GETALL: "Enquiry/GetAll",
    ENQUIRY_GET_BY_ID: "Enquiry/GetById/",
    ENQUIRY_GETALL_INFO: "Enquiry/GetList",
    ENQUIRY_ADD: "Enquiry/Save",
    ENQUIRY_UPDATE: "Enquiry/Update",
    ENQUIRY_STUDENTS: "Enquiry/GetStudents",
    ENQUIRY_APPROVE: "Enquiry/Approve",
    ENQUIRY_UPDATE_LIST: "Enquiry/UpdateList",
    ENQUIRY_ADD_ATTACHMENTS: "Enquiry/AddAttachment",
    ENQUIRY_CSV_DOWNLOAD: "Enquiry/GetCsv",
    ENQUIRY_REJECTION: "Enquiry/RejectEnquiry",
    ENQUIRY_SET_STEPS: "Enquiry/SetStep",
    ENQUIRY_ASSIGNCARD: "Enquiry/AssignCard",
    ENQUIRY_RESYNC_STUDENT: "Enquiry/ResyncStudent",
    ENQUIRY_DELETE_STUDENT_CARD: "Enquiry/DeleteStudentCard",
    ENQUIRY_GET_STUDENT_CARD: "Enquiry/GetStudentCard",
    ENQUIRY_SEND_EMAIL_INVOICE: "Enquiry/SendEmailInvoice",
    ENQUIRY_ADD_CREDENTIALS: "Enquiry/AddCredentials",
    ATTENDANCE_GETALL: "Attendance/GetAll",
    ATTENDANCE_ADD: "Attendance/Add",
    ATTENDANCE_UPDATE: "Attendance/Update",
    ATTENDANCE_ManualEntry: "Attendance/ManualEntry",
    ATTENDANCE_SHEET: "Attendance/AttendanceSheet",
    MASTERS_GETALL: "IdName/GetAll",
    MASTERS_ADD: "IdName/Add",
    MASTERS_ADD_BATCH: "Batch/Add",
    MASTERS_GET_BATCH: "Batch/GetAll",
    PAYMENT_ADD: "Payment/Add",
    PAYMENT_UPDATE: "Payment/UpdateFees",
    PAYMENT_BY_ID: "Payment/GetBy",
    PAYMENT_GETALL: "Payment/GetAll",
    PAYMENT_GET_LIST: "Payment/GetList",
    PAYMENT_GET_VIEW: "Payment/GetPdf",
    PAYMENT_DOWNLOAD: "Payment/DownloadPdf",
    PAYMENT_BOTH_DOWNLOAD: "Payment/DownloadBothPdf",
    GET_MASTER_ALL: "Masters/GetAllMasters",
    GET_MASTER_SEARCH: "Masters/Search",
    SAVE_STATUS_ACTION: "Masters/StatusAction",
    GET_USER_LIST: "Auth/UserList",
    GET_COUNSELORS_LIST: "Enquiry/GetCounselors",
    SAVE_USER: "Auth/SaveUser",
    GET_USER_BY_ID: "Auth/GetUser",
    GET_SUBJECTS_BY_DEPARTMENT: "Masters/SubjectsByDepartment",
    GET_SEMESTERS_BY_DEPARTMENT: "Masters/SemestersByDepartment",
    GET_COURSE_BY_DEPARTMENT: "Masters/CoursesByDepartment",
    GET_SCHEMAS_LIST: "Masters/Schemas",
    APPLY_CURRENT_SCHEMAS: "Masters/CurrentSchema",
    GET_CURRENT_SCHEMA_BY_COURSE: "Masters/CurrentSchemasByCourse",
    POST_MASTER: "Masters/",
    GET_SEMESTERS_WITH_SUBJECTS: "Masters/SemesterWithSubjects",
    GET_PROCESS_STEPS: "ProcessSteps",
    POST_PROCESS_STEPS: "ProcessSteps",
    GET_PROCESS_STEP_BY_ID: "ProcessSteps/GetById",
    GetEnquiryForm: "UnauthorizedAccess/GetEnquiryForm",
    GetMasterList: "UnauthorizedAccess/GetAllMasters",
    SaveWebEnquiry: "UnauthorizedAccess/Save",
    EnquiryCommunication: "EnquiryCommunication/Save",
    CHANGE_COUNSELORS_CALL_STATUS: "Enquiry/ChangeCounselorsCallStatus",
    EnquiryCommunication_GETBYID: "EnquiryCommunication/CommunicationGetById",
    EnquiryCommunication_GETALL: "EnquiryCommunication/GetAllList",
    EnrollForm_GET: "EnquiryCommunication/GetEnrollPdf",
    Download_View_Pdf: "EnquiryCommunication/GetDownloadView",
    GET_LOG: "UserLogs",
    GET_SESSION_LIST: "Masters/GetSessionListByCourseId",
    GET_SEMESTER_LIST: "Masters/GetSemesterListBySessionId",
    GET_SUBJECT_LIST: "Masters/GetSubjectListBySessionId",
    FEES_CSV_DOWNLOAD: "Payment/DownloadCSV",
    GET_COURSE_DETAILS: "Masters/GetCourseDetails",
    GET_FACULTY_DETAILS: "Masters/GetFacultyDetails",
    GET_SECTIONS_DETAILS: "Masters/GetSectionsDetails",
    GET_BATCH_SEARCHING: "Masters/batchSearching",
    GET_MASTER_TYPE: "Masters",
    GET_COMMON_WEIGHT: "Assessments/GetAssessmentsWeight",
    SCHEDULE_CREATE: "CreateSchedule/Save",
    SCHEDULE_GETLIST: "CreateSchedule/GetAllList",
    GET_SEMESTER_DATA: "Masters/GetSemesterData",
    GET_ECARD_LIST: "Ecard/EcardList",
    POST_ECARD: "Ecard/AddEdit",
    GET_BIOMATRIC_DEVICE_LIST: process.env?.REACT_APP_BIO_MATIRC_URL,
    GET_SUBJECT_ALLOCATION_MASTER_DETAILS: "Masters/GetSubjectAllocationMasterDetails",
    GET_SUBJECT_ALLOCATION_BY_ID: "SubjectAllocation/GetSubjectAllocationById",
    ADD_SUBJECTALLOCATION: "SubjectAllocation/Save",
    UPDATE_SUBJECTALLOCATION: "SubjectAllocation/Update",
    SCHEDULE_GET_BY_ID: "CreateSchedule/GetById",
    ASSIGN_CARD: "ECard/AssignCard",
    POST_ASSESSMENTS_DATA: "Assessments/Save",
    GET_ASSESSMENTS_DATA: "Assessments/GetAssessments",
    UPDATE_ASSESSMENT_STATUS: "Assessments/UpdateAssessmentStatus",
    DELETE_SCHEDULE: "CreateSchedule/DeleteSchedule",
    UPDATE_SCHEDULE: "CreateSchedule/UpdateScheduleList",
    SCHEDULE_ITEM_LIST: "CreateSchedule/GetScheduleItemList",
    GET_LIST_WITH_ID_NAME: "CreateSchedule/GetListWithIdName",
    GET_ENQUIRY_LIST_WTIH_IDNAME:"Enquiry/GetListWithIdName",
    POST_ASSESSMENTS_WEIGHT:"Assessments/AssessmentsWeight",
    POST_ASSESSMENTS_WEIGHT_DETAILS:"Assessments/AssessmentsWeightWithDetail",
    UPDATE_RECORD_STATUS:"Masters/UpdateRecordStatus",
    GET_BATCH_STUDENT :"Attendance/GetAllAttByBatch",
    GET_ALL_STUDENT_ATTENDANCE :"Attendance/GetAllStudentAttendance",
    REVIVE_RECORD:"Masters/ReviveRecord",
    ENQUIRY_EXISTS:"Enquiry/Exists",
    GET_STUDENT_STATUS:"DashBoard/GetStudentStatus",
    RESET_PASSWORD:"Auth/ChangePassword",
    TATA_TELE_CLICK_CALL:"TataAuthToken/ClickToCall/",
    TATA_CALL_LOG_LIST:"TataAuthToken/GetAllCallLogs",
    LEAVE_APPLICATION_ADMIN:"Request/LeaveApplicationAdmin",
    LEAVE_ACTION:"Request/LeaveAction",
    CREATE_UPDATE_LEAVE:"Request/CreateOrUpdateLeaveType",
    GET_LEAVE_TYPES:"Request/GetLeaveTypes",
    POST_PRIVACY_POLICY:"Masters/AddPrivacyPolicy",
    GET_PRIVACY_POLICY:"App/GetPrivacyPolicy",
    DOWNLOAD_SHEET:"Attendance/DownloadSheet",
    GET_STREAM:"DashBoard/GetStraeamData",
    SUBJECT_ALLOCATION_GETLIST:"SubjectAllocation/GetSubAllocationList",
    GET_SCHEDULE_ITEM_BY_ID:"CreateSchedule/GetScheduleListById",
    UPDATE_COURSE_DETAILS:"Enquiry/UpdateStudentCourse",
    GET_SUBJECTWISE_ATTENDANCE_SHEET:"Attendance/GetSubjectWiseAttandanceSheet",
    GET_BATCH_WISE_SUBJECTS:"Attendance/GetBatchwiseSubjects",
    GET_ATTENDANCE_REQUEST:"Request/GetAttendanceReq",
    POST_ATTENDANCE_REQUEST:"Request/AttendanceReqAction",
    GET_STUDENT_REPORTS:"Reports/GetStudentsReports",
    GET_BIOMETRIC_DEVICE_LIST:"Enquiry/GetBiometricDeviceList",
    GET_STUDENT_WITHOUT_BIO_DEVICE_ASSIGN:"Enquiry/StudentWithOutBioDeviceAssign",
    DOWNLOAD_STUDENT_REPORT_CSV:"Reports/DownloadReportCsv",
    STUDENT_BIO_DEVICE_ASSIGN:"Enquiry/StudentBioDeviceAssign",
    SAVE_BIOMETRIC_CHANGE:"Enquiry/SaveBioMatricChange",
    GET_APP_VERSION:"App/GetAppVersion",
    SAVE_APP_VERSION:"App/SaveAppVersion",
    GET_SUPPORT_REQUEST:"Request/GetSupportReqList",
    POST_SUPPORT_REQUEST:"Request/SupportReqAction",
    GET_SOURCE_BY_DATE:"DashBoard/GetSourseByDate",
    GET_ASSESSMENT_BY_DESIGNCOURSE:"Assessments/GetAssessmentByDesignCourse",
    GET_STUDENT_BY_ASSESSMENT:"Assessments/GetStudentByAssessment",
    SAVE_ALL_SUBJECT_MARKS:"Assessments/SaveAllSubjectMarks",
    STUDENTS_UPLOAD_PROFILE:"Enquiry/UploadProfile",
    GET_SUBJECT_MARKS:"Assessments/GetSubjectMarks",
    GET_ASSESSMENT_SUBJECT:"Assessments/GetAssessmentSubject",
    GET_STUDENT_BY_ASSESSMENT_SUBJECT:"Assessments/GetStudentByAssessmentSubject",
    SAVE_SINGLE_SUBJECT_MARKS:"Assessments/SaveSingleSubjectMarks",
    GET_RESULT_BY_DESIGN_COURSE:"Assessments/GetResultByDesignCourse",
    DOWNLOAD_SUBJECT_MARKS_CSV:"Assessments/DownloadSubjectMarksCsv",
    GET_SINGLE_STUDENT_CSV:"Assessments/GetSingleStudentResultCsv",
    GET_ENQUIRY_BY_SOURCE:"/DashBoard/GetEnquiryBySource",
    GET_NOT_ASSIGNED_ENQUIRIES:"/Enquiry/GetNotAssignedEnquiries",
    GET_ENQUIRY_CALL_LIST:"Enquiry/GetEnquiryCallList",
    ADD_COUNSELORS_INTO_ENQUIRY:"/Enquiry/AddCounselorsIntoEnquiry",
    CHANGE_ENQUIRY_COMMUNICATION_DETAILS:"/Enquiry/ChangeEnquiryCommunicationDetails",
 };

  static GET = async (URL_BASE: string, URLParameters: any) => {
    return await ApiUtility.get(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString()
    );
  };

  static POST = async (
    URL_BASE: string,
    URLParameters: any = {},
    requestBody: any
  ) => {
    return await ApiUtility.post(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
      requestBody
    );
  };
  static POST_FROM = async (
    URL_BASE: string,
    URLParameters: any = {},
    requestBody: any
  ) => {
    return await ApiUtility.postForm(
      URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
      requestBody
    );
  };
}

export default HTTPSCalls;
