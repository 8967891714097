import { Button, ConfigProvider, TableProps, Typography } from "antd";
import React from "react";
import { Col, Row, Select, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import {
  useGetEnquiriesCounselorList,
  useGetNotAssignedEnquiries,
} from "../../Utilities/tanStackQueries";
import dayjs from "dayjs";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import AddCounsellorsModal from "./AddCounsellorsModal";
import { IdNameType } from "../../Types";
import { EnquiryCounselorActionOptions } from "../../Constant/Enums";
import { AssigneeAvatar } from "../../Common/AssigneeAvatar";
import { ColumnsType } from "antd/es/table";
import { isUserAdmin } from "../../Utilities/Utils";
import { userData as UD } from "../../Store/Slice/userSlice";
import { useSelector } from "react-redux";

const UnassignedEnquiryList = () => {
  const userData = useSelector(UD);
  const [counselorModalOpen, setCounselorModalOpen] = useState<boolean>(false);
  // const [record, setRecord] = useState<any>(null);
  const [selectedEnquiryCounselor, setSelectedEnquiryCounselor] = useState<any>(
    isUserAdmin()
      ? EnquiryCounselorActionOptions.NotAssigned
      : EnquiryCounselorActionOptions.Assigned
  );
  const [paggingItems, setPaggingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [start, setStart] = useState<number>(0);
  const [length, setLength] = useState<number>(15);
  const [enquirySeach, setEnquirySearch] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<IdNameType[]>([]);
  // const [counselors, setCounselors] = useState<IdNameType[]>([]);

  const [selectedCounselor, setSelectedCounselor] = useState<
    string | undefined
  >(isUserAdmin() ? undefined : userData?.id);
  // const {
  //   data: notAssignedCounselorEnquiry,
  //   isLoading: enquiryCounselorLoading,
  //   refetch: GetNotAssignedEnquiriesRefetch,
  // } = useGetNotAssignedEnquiries({start,length, search: enquirySeach,action: selectedEnquiryCounselor});
  const {
    data: notAssignedCounselorEnquiry,
    isLoading: enquiryCounselorLoading,
    isFetching: enquiryCounselorFetching,
    refetch: GetNotAssignedEnquiriesRefetch,
  } = useGetNotAssignedEnquiries({
    start,
    length,
    search: enquirySeach,
    action: selectedEnquiryCounselor,
    ...(selectedCounselor && { counselorId: selectedCounselor }),
  });

  const { data: counselorList } = useGetEnquiriesCounselorList();

  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  setSelectedCounselor(value);
                  // props?.changeListParams(inputName, value);
                  // Type guard to check if changeListParams is defined
                  if (changeListParams) {
                    changeListParams(inputName, value);
                  }
                  // setListParams({ ...listParams, start: 0 });
                  // setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  setSelectedCounselor(undefined);
                  // if (
                  //   inputName === "counselors" ||
                  //   inputName === "sourceSearch"
                  // )
                  //   changeListParams(inputName, "");
                  // else changeListParams(inputName);
                  // setListParams({ ...listParams, start: 0 });
                  // setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
                value={selectedCounselor}
                // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                onChange={(e) => {
                  if (changeListParams) {
                    changeListParams(inputName, e.target?.value);
                  }
                  // props?.changeListParams(inputName, e.target?.value)
                }}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };
  const enquiryColumns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "name",
      width: "30%",
    },
    {
      title: "Enquiry Date",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      render: (text: any, record: any) => {
        return <div>{dayjs(record?.enquiryDate).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "contactDetail",
      key: "name",
      render: (text: any, record: any) => {
        return <div>{text?.mobileNumber}</div>;
      },
    },
    {
      // title: "Counselor",
      title: isUserAdmin() ? (
        <THeader
          title={"Counselling"}
          inputName={"counselors"}
          // changeListParams={changeListParams}
          // setShouldRefresh={setRefresh}
          // listParams={props?.listParams1}
          // setListParams={props?.setListParams1}
          ifSelect={true}
          option={counselorList?.result?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
          }))}
        />
      ) : (
        "Counselors"
      ),
      dataIndex: "counselors",
      key: "counselors",
      responsive: ["xl"],
      width: 150,
      render: (val: any) => (
        <span className=" font13">
          {!!val ? <AssigneeAvatar data={val} size={"small"} /> : "-"}
        </span>
      ),
    },
    // {
    //   title: "",
    //   width: "1%",
    //   render: (_: any, record: any) => (
    //     <Row justify="end">
    //       {/* <FormOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd", cursor: "pointer" }}
    //         onClick={() => {
    //           setRecord(record);
    //           setCounselorModalOpen(true);
    //         }}
    //       /> */}
    //     </Row>
    //   ),
    // },
  ];

  //   useEffect(() => {
  //     if (notAssignedCounselorEnquiry) {
  //         setPagingItems((p) => {
  //             p.totalRecords = notAssignedCounselorEnquiry?.result?.totalRecords;
  //             return p;
  //           });
  //         }
  //   }, [notAssignedCounselorEnquiry]);
  // useEffect(() => {
  //     setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
  //     setLength(pagingItems?.pageSize);
  //   }, [pagingItems]);

  const hasSelected = selectedRows.length > 0;
  const rowSelection: TableProps["rowSelection"] = {
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(
        selectedRows.map((row) => ({ id: row?.id, name: row?.fullName }))
      );
    },
  };

  useEffect(() => {
    if (notAssignedCounselorEnquiry) {
      setPaggingItems((p) => {
        p.totalRecords = notAssignedCounselorEnquiry?.result?.totalRecords;
        return p;
      });
    }
  }, [notAssignedCounselorEnquiry]);

  useEffect(() => {
    setStart((paggingItems?.currentPage - 1) * paggingItems?.pageSize);
    setLength(paggingItems?.pageSize);
  }, [paggingItems]);

  return (
    <>
      <div className="py-2 roundedCornerMedium bg-white">
        <Row
          gutter={18}
          style={{ padding: 12 }}
          justify="space-between"
          align={"middle"}
        >
          <Col span={11} style={{ alignItems: "start" }}>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
            Leads Allocation
            </Typography.Title>
          </Col>
          <Col
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
              color:"green"
            }}
            onClick={() => GetNotAssignedEnquiriesRefetch()}
          >
            <ReloadOutlined size={95} />
          </Col>
          {isUserAdmin() && (
            <Col span={4}>
              <Select
                popupClassName="cit-select-box"
                placeholder={"Select Status"}
                options={[
                  { label: "All", value: EnquiryCounselorActionOptions.All },
                  {
                    label: "Assigned",
                    value: EnquiryCounselorActionOptions.Assigned,
                  },
                  {
                    label: "Not Assigned",
                    value: EnquiryCounselorActionOptions.NotAssigned,
                  },
                ]}
                // showSearch
                defaultValue={selectedEnquiryCounselor}
                // options={getBatchOptions()}
                style={{ width: "100%" }}
                // filterOption={(input, option: any) =>
                //   (option?.label ?? "")
                //     ?.toLowerCase()
                //     .includes(input?.trim().toLowerCase())
                // }
                onSelect={(val: any) => {
                  setSelectedEnquiryCounselor(val);
                }}
              />
            </Col>
          )}
          <Col span={5}>
            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input"
              onSearch={(val: string) => {
                setEnquirySearch(val);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          {isUserAdmin() && (
            <Col span={3}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => setCounselorModalOpen(true)}
                disabled={!hasSelected}
              >
                Submit
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Table
              {...(isUserAdmin() && { rowSelection: { ...rowSelection } })}
              className="masterList"
              loading={{
                spinning: enquiryCounselorLoading ||enquiryCounselorFetching,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              style={{
                width: "100vw",
                borderBottom: "none",
                minHeight: 328,
                overflow: "auto",
              }}
              size="small"
              scroll={{ y: "240px" }}
              dataSource={notAssignedCounselorEnquiry?.result?.items}
              columns={enquiryColumns}
              // pagination={false}
              rowKey={(record) => record?.id}
              pagination={{
                selectPrefixCls: "mx-3",
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: paggingItems.currentPage,
                pageSize: paggingItems.pageSize,
                showSizeChanger: true,
                total: notAssignedCounselorEnquiry?.result?.totalRecords || 0,
              }}
              onChange={(paging, filter, sort) => {
                setPaggingItems((prev) => ({
                  ...prev,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 10,
                }));
              }}
            />
          </Col>
        </Row>
      </div>
      <AddCounsellorsModal
        open={counselorModalOpen}
        handleCancel={() => {
          // setRecord(null);
          setSelectedRows([]);
          setCounselorModalOpen(false);
        }}
        record={{ enquiriesIds: selectedRows }}
        setRefresh={GetNotAssignedEnquiriesRefetch}
      />
    </>
  );
};
export default UnassignedEnquiryList;
