import { Col, DatePicker, Form, message, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { queryClient } from "../../App";

const AddCounsellorsModal = (props: {
  open: boolean;
  handleCancel: Function;
  record: any;
  setRefresh: any;
  isUpdate?: boolean;
}) => {
  const { open, handleCancel, record, setRefresh, isUpdate } = props;
  const [form] = Form.useForm();
  // const [counselorDate, setCounselorDate] = useState<any>(dayjs());
  const [loading, setLoading] = useState<boolean>(false);
  const [counselors, setCounselors] = useState<
    { label: string; value: string }[]
  >([]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formValues = form.getFieldsValue();
      let payload, url;
      if (isUpdate) {
        payload = {
          id: record?.id,
          date: dayjs(formValues?.date).format("MM-DD-YYYY"),
          counselors: formValues.counselors.map(
            (counselor: { label: string; value: string }) => {
              return { id: counselor?.value, name: counselor?.label };
            }
          ),
        };
        url = HTTPSCalls.ENDPOINTS.CHANGE_ENQUIRY_COMMUNICATION_DETAILS;
      } else {
        payload = {
          id: record?.enquiriesIds?.map((record: any) => record?.id),
          date: dayjs(formValues?.date).format("MM-DD-YYYY"),
          counselors: formValues.counselors.map(
            (counselor: { label: string; value: string }) => {
              return { id: counselor?.value, name: counselor?.label };
            }
          ),
        };
        url = HTTPSCalls.ENDPOINTS.ADD_COUNSELORS_INTO_ENQUIRY;
      }
      let res = await HTTPSCalls.POST(url, {}, payload);
      if (res?.status) {
        message.success("Counselors assigned successfully.");
        queryClient.invalidateQueries({
          queryKey: ["getEnquiriesCallList"],
        });
        setRefresh();
      }
      form.resetFields();
      handleCancel();
    } catch (error: any) {
      message.error(
        error.message || "An error occurred while assign the Counselors."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getList = async () => {
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_COUNSELORS_LIST + "?",
        {}
      );
      if (res?.result?.length > 0) {
        setCounselors(
          res?.result?.map((item: any) => ({
            value: item?.id,
            label: item?.name,
            // role: item?.role,
          }))
        );
      }
    };
    open && getList();
  }, [open]);

  useEffect(() => {
    if (record?.date && record?.assignTo) {
      form.setFieldsValue({
        date: dayjs(record?.date),
        counselors: record?.assignTo?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
      });
    }
  }, [record]);
  return (
    <>
      <Modal
        okText={"Save"}
        title="Add Counselors"
        width={700}
        open={open}
        onCancel={() => handleCancel()}
        onOk={() =>
          form
            .validateFields()
            .then(handleSubmit)
            .catch((err) => {
              console.log(err);
            })
        }
        confirmLoading={loading}
        // onCancel={handleCancel}
      >
        <div
          style={{
            height: "100%",
            border: "1.5px dashed lightgray",
            borderRadius: "5px",
            padding: "10px 10px 0px 20px",
            color: "black",
          }}
        >
          <Form
            requiredMark={false}
            form={form}
            style={{ lineHeight: 2.5 }}
            initialValues={{ date: dayjs() }}
            labelCol={{ span: 6 }}
            colon={false}
            wrapperCol={{ span: 10 }}
          >
            <Form.Item className="AlignStart mt-2" label="Enquiries' Name">
              <span>
                {isUpdate
                  ? record?.name
                  : record?.enquiriesIds
                      ?.map((item: any) => item?.name)
                      .join(", ")}
              </span>
            </Form.Item>

            <Form.Item
              className="AlignStart mt-2"
              label="Counselors"
              name="counselors"
              rules={[{ required: true, message: "Please select a counselor" }]}
              style={{ textAlign: "left" }}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                labelInValue={true}
                placeholder="Select Counselors"
                style={{ width: "100%" }}
                options={counselors}
                filterOption={(inputValue, option: any) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              className="AlignStart mt-2 "
              label="Date"
              name="date"
              style={{ textAlign: "left" }}
            >
              <DatePicker
                name="date"
                format="DD/MM/YYYY"
                allowClear={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddCounsellorsModal;
