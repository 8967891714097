import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import "./EnquiryLead.css";
import dayjs from "dayjs";
import HTTPSCalls from "../../Services/HTTPCalls";
import {
  CallType,
  EnquiryCallStatusEnum,
} from "../../Constant/Enums";
import { queryClient } from "../../App";

const CallLogModel: React.FC<{
  isEditable: boolean;
  open: boolean;
  record: any;
  onCancel: () => void;
  onSave: () => void;
}> = (props) => {
  const [form] = Form.useForm();
  // const [createTask, setCreateTask] = React.useState<boolean>(false);
  // const [dncModelOpen, setDncModelOpen] = React.useState<boolean>(false);
  // const [textValue, setTextValue] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [enquiryCallStatus, setEnquiryCallStatus] = React.useState<EnquiryCallStatusEnum>(EnquiryCallStatusEnum.Pending);
  // const [attachmentList, setAttachmentList] = React.useState<any[]>([
  //   {
  //     fileType: "",
  //     fileName: {
  //       id: "",
  //       name: "",
  //       contentType: "",
  //       length: 0,
  //       path: "",
  //     },
  //     description: "",
  //   },
  // ]);
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setButtonLoading(true);
        let data:any = {
          id: props?.record?.id,
          enquiryCallStatus: values?.enquiryCallStatus,
          note: values?.note,
        };
        if (enquiryCallStatus === EnquiryCallStatusEnum.FollowUp) {
          data.date = dayjs(values?.followUpDate).format("MM-DD-YYYY");
        }
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.CHANGE_COUNSELORS_CALL_STATUS,
          {},
          data
        );
        if (response.status) {
          queryClient.invalidateQueries({queryKey:["getEnquiriesCallList"]});
          form.resetFields();
          message.success("Call Log Updated");
          props.onSave();
        } else {
          message.error(response.message);
        }
      })
      .catch((error: any) => {
        console.log(error);
      }).finally(()=>{
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    if (props.record) {
      form.setFieldsValue({
        name: props?.record?.name,
        number: props?.record?.number,
        callType: props?.record?.callType,
        enquiryCallStatus: props?.record?.enquiryCallStatus,
        note: props?.record?.note,
        // attachments: props?.record?.attachments,
      });

      setEnquiryCallStatus(props?.record?.enquiryCallStatus);
      
      if (
        props?.record?.followUpDate &&
        props.record.followUpDate !== "0001-01-01T00:00:00"
      ) {
        form.setFieldValue("followUpDate", dayjs(props.record.followUpDate));
      } 

    }
  }, [props?.record]);

  // const getBase64 = (file: RcFile): Promise<string> =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // const handelFile = async (file: any, fileList: any) => {
  //   fileList?.map(async (itm: any, index: any) => {
  //     let prevAtt = [...attachmentList];
  //     let currentFileBase64 = await getBase64(itm as RcFile);

  //     let fileInfo = {
  //       id: "",
  //       name: itm?.name,
  //       contentType: itm?.type,
  //       length: itm?.size,
  //       path: currentFileBase64,
  //     };

  //     prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

  //     setAttachmentList(prevAtt);
  //   })
  // }
  // const customRender = (
  //   originNode: React.ReactElement<any>,
  //   file: UploadFile,
  //   fileList: Array<UploadFile>
  // ) => {
  //   return (
  //     <>
  //       {/* <div className="ant-upload-list-item">
  //                   {
  //                     file.name
  //                   }

  //                 </div> */}
  //       {originNode}
  //       {/* <h1>This is heading</h1> */}
  //     </>
  //   );
  // };
  // const uploadProps: UploadProps = {
  //   name: "file",
  //   multiple: true,
  //   showUploadList: {
  //     previewIcon: true,
  //     showDownloadIcon: true,
  //     downloadIcon: "Download",
  //     showRemoveIcon: true,
  //   },
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== "uploading") {
  //     }
  //     if (status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onDrop(e) { },
  //   beforeUpload(file, FileList) {
  //     handelFile(file, FileList);
  //     return false;
  //   },
  //   itemRender: customRender,
  // };
  return (
    <>
      <Modal
        title={`Add Call Log`}
        maskClosable={false}
        open={props.open}
        onCancel={() => props.onCancel()}
        width={1080}
        footer={
          props?.isEditable &&
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={() => props.onCancel()}>Cancel</Button>
            <Button
              onClick={onSubmit}
              type="primary"
              loading={buttonLoading}
              disabled={
                props?.record?.enquiryCallStatus !==
                EnquiryCallStatusEnum.Pending
              }
            >
              {props?.record?.id === "" ? "Save" : "Update"}
            </Button>
          </Space>
        }
      >
        {/* <Divider className="marginDivider" /> */}

        <div style={{ maxHeight: "59vh", overflowY: "auto", padding: "7px" }}>
          <Form
            form={form}
            requiredMark={true}
            layout="vertical"
            initialValues={{
              followUpDate:dayjs().add(1, "days")
            }}
            disabled={!props?.isEditable||
              props?.record?.enquiryCallStatus !== EnquiryCallStatusEnum.Pending
            }
          >
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="name" label="Name" className="marginbtm">
                  <Input style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="number"
                  label="Phone Number"
                  className="marginbtm"
                >
                  <InputNumber
                    disabled
                    style={{ width: "100%" }}
                    maxLength={10}
                    minLength={10}
                    controls={false}
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="callType"
                  label="Call Type"
                  className="marginbtm"
                  rules={[{ required: true, message: "Select Call Type" }]}
                >
                  <Select
                    disabled
                    placeholder="Choose Type"
                    options={[
                      {
                        value: CallType.Incoming,
                        label: "Incoming",
                      },
                      {
                        value: CallType.Outgoing,
                        label: "Outgoing",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="enquiryCallStatus"
                  label="Call Status"
                  className="marginbtm"
                >
                  <Select
                    placeholder="Choose Outcome"
                    options={[
                      {
                        value: EnquiryCallStatusEnum.Pending,
                        label: "Pending",
                      },
                      {
                        value: EnquiryCallStatusEnum.FollowUp,
                        label: "Follow-Up",
                      },
                      {
                        value: EnquiryCallStatusEnum.Interested,
                        label: "Interested",
                      },
                      {
                        value: EnquiryCallStatusEnum.NotInterested,
                        label: "Not Interested",
                      },
                    ]}
                    onChange={(val)=>setEnquiryCallStatus(val)}
                  />
                </Form.Item>
              </Col>
            </Row>
            { enquiryCallStatus === EnquiryCallStatusEnum.FollowUp &&
            <Row>
              <Col span={6}>
                <Form.Item
                  name="followUpDate"
                  label="Follow Up Date"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Follow Up Date!",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"                    
                  />
                </Form.Item>
              </Col>
            </Row>
            }
            <Form.Item
              name="note"
              label="Note"
              className="marginbtm"
              rules={[
                {
                  required: true,
                  message: "Please Enter Notes!",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                showCount
                placeholder="Add Note Here....."
                maxLength={100}
                onChange={(e: any) => {
                  // setTextValue(e.target.value);
                  //console.log(textValue, "textValue");
                }}
              />
            </Form.Item>
            {/* <Form.Item
              name={"attachments"}
              label="Add Attachment(s)"
            // rules={[
            //   {
            //     required: true,
            //     message: "this field is required",
            //   },
            // ]}
            >
              <Dragger
                {...uploadProps}
              // onPreview={async (file: UploadFile) => {
              //     console.log("testtest = ", await getBase64(
              //       file.originFileObj as any
              //     ))
              // }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Dragger>
            </Form.Item> */}
            {/* <Form.Item
              name="followUpTask"
              valuePropName="checked"
              className="marginbtm"
            >
              <Checkbox
                onChange={(e) => {
                  setCreateTask(e.target.checked);
                }}
              >
                <span className="themeColor fw-500">Create follow up task</span>
              </Checkbox>
            </Form.Item> */}
            {/* {createTask && (
              <>
                <Form.Item
                  name="startDate"
                  label="Choose Date"
                  className="marginbtm"
                  rules={[{ required: true, message: "Select Date" }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    //defaultValue={dayjs()}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Form.Item>

                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item name="startTime" className="marginbtm">
                      <TimePicker
                        style={{ width: "100%" }}
                        format={"h:mm A"}
                        placeholder="StartTime"
                      //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item name="endTime" className="marginbtm">
                      <TimePicker
                        style={{ width: "100%" }}
                        format={"h:mm A"}
                        placeholder="EndTime"
                      //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )} */}
          </Form>
        </div>
      </Modal>
      {/* {dncModelOpen && (
        <DNCModel
          open={dncModelOpen}
          text={"Are you sure you want to add this number in DNC ?"}
          onOk={() => setDncModelOpen(false)}
          onCancel={() => setDncModelOpen(false)}
        />
      )} */}
    </>
  );
};

export default CallLogModel;
