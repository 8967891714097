import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import {
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AddEditDepartment from "./Department/AddEditDepartment";
import { MasterTypes } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import AddEditCourse from "./Course/AddEditCourse";
import AddEditSchema from "./Schema/AddEditSchema";
import AddEditSemester from "./Semester/AddEditSemester";
import AddEditSubject from "./Subject/AddEditSubject";
import dayjs from "dayjs";
import "./../../Common/Common.css"
import AddEditBatch from "./Batch/AddEditBatch";
import { weekdays } from "moment";
import AddEditSections from "./Section/AddEditSections";
const DesignCourse = () => {
  const [form] = Form.useForm();
  const [show, setShow] = useState("");
  const [editData, setEditData] = useState<any | undefined>(undefined)
  const [selectedRecord, setSelectedRecord] = useState<{
    department: any;
    course: any;
    schema: any;
    semester: any;
    batch:any;
    // section: any
  }>({
    course: undefined,
    department: undefined,
    schema: undefined,
    semester: undefined,
    batch:undefined,
    // section: undefined
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [details, setDetails] = useState<{
    departments: any;
    courses: any;
    schemas: any;
    semesters: any;
    subjects: any;
    batch:any;
    section: any;
  }>({
    courses: [],
    departments: [],
    schemas: [],
    semesters: [],
    subjects: [],
    batch:[],
    section: []
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [apiDataLoading, setApiDataLoading] = useState<boolean>(false);
  const [postData, setPostData] = useState<any>(undefined);
  const batchDaysData: any = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const [batchDays, setBatchDays] = useState<any>({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });

  const commonColumns: any = [
    {
      title: "Created At",
      dataIndex: "CreatedAt",
      width:"35%",
      render: (_: any, record: any) => (
        <div className="createUpdateDate">
          {
            record?.createdBy ? dayjs(record?.createdBy?.date)?.format("DD MMM YYYY") : "-"
          }
        </div>
      ),
    },
    // {
    //   title: "UpdatedAt",
    //   dataIndex: "updatedAt",
    //   render: (_: any, record: any) => (
    //     <div className="createUpdateDate">
    //       {
    //         record?.updatedBy ? dayjs(record?.updatedBy?.date)?.format("DD MMM YYYY HH:mm") : "-"
    //       }
    //     </div>
    //   ),
    // }

  ]

  const departmentColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div className={record?.id === selectedRecord?.department?.id ? "selectedRecord" : ""}>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursor editIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("department")
          }} />
        </>
      ),
    },
  ];
  const courseColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div className={record?.id === selectedRecord?.course?.id ? "selectedRecord" : ""}>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursor editIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("course")
          }} />
        </>
      ),
    },
  ];
  const schemaColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div className={record?.id === selectedRecord?.schema?.id ? "selectedRecord" : ""}>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursor editIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("schema")
          }} />
        </>
      ),
    },
  ];
  const semesterColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div className={record?.id === selectedRecord?.semester?.id ? "selectedRecord" : ""}>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursor editIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("semester")
          }} />
        </>
      ),
    },
  ];
  const subjectColumns: any = [
    {
      title: "",
      dataIndex: "",
      width:"8%"
      //  render:()
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursoreditIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("subject")
          }} />
        </>
      ),
    },
  ];
  const batchColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursoreditIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("batch")
          }} />
        </>
      ),
    },
  ];
  const sectionColumns: any = [
    {
      title: "",
      dataIndex: "",
      width:"8%"
      //  render:()
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      width:"10%",
      render: (_: any, record: any) => (
        <>
          <Button icon={<FormOutlined className="cursor editIconColour" />} size="small" type="link" onClick={() => {
            setEditData(record)
            setShow("section")
          }} />
        </>
      ),
    },
  ];
  const getComponent = (): ReactNode => {
    if (show === "department")
      return <AddEditDepartment data={editData} form={form} />;

    if (show === "course")
      return (
        <AddEditCourse
          data={{
            ...editData,
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
          }}
          form={form}
        />
      );
    if (show === "schema")
      return (
        <AddEditSchema
          data={{
            ...getSchemaData(),
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
            course: {
              label: selectedRecord?.course?.name,
              value: selectedRecord?.course?.id,
            },
          }}
          form={form}
        />
      );
    if (show === "semester")
      return (
        <AddEditSemester
          data={{
            ...editData,
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
            course: {
              label: selectedRecord?.course?.name,
              value: selectedRecord?.course?.id,
            },
            schema: {
              label: selectedRecord?.schema?.name,
              value: selectedRecord?.schema?.id,
            },
            // section: selectedRecord?.section?.map((itm:any)=>({
            //   label:itm?.name,
            //   value:itm?.id
            // }))
          }}
          form={form}
        />
      );
      if (show === "batch")
        return (
          <AddEditBatch
            data={{
              ...editData,
              department: {
                label: selectedRecord?.department?.name,
                value: selectedRecord?.department?.id,
              },
              course: {
                label: selectedRecord?.course?.name,
                value: selectedRecord?.course?.id,
              },
              schema: {
                label: selectedRecord?.schema?.name,
                value: selectedRecord?.schema?.id,
              },
              // section: selectedRecord?.section?.map((itm:any)=>({
              //   label:itm?.name,
              //   value:itm?.id
              // }))
            }}
            form={form}
            batchDaysData={batchDaysData}
            batchDays={batchDays}
            setBatchDays={setBatchDays}
          />
        );
    if (show === "subject")
      return (
        <AddEditSubject
          data={{
            ...editData,
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
            course: {
              label: selectedRecord?.course?.name,
              value: selectedRecord?.course?.id,
            },
            schema: {
              label: selectedRecord?.schema?.name,
              value: selectedRecord?.schema?.id,
            },
            semester: {
              label: selectedRecord?.semester?.name,
              value: selectedRecord?.semester?.id,
            },
          }}
          form={form}
        />
      );
      if (show === "section")
        return (
          <AddEditSections
            data={{
              ...editData,
              department: {
                label: selectedRecord?.department?.name,
                value: selectedRecord?.department?.id,
              },
              course: {
                label: selectedRecord?.course?.name,
                value: selectedRecord?.course?.id,
              },
              schema: {
                label: selectedRecord?.schema?.name,
                value: selectedRecord?.schema?.id,
              },
              batch: {
                label: selectedRecord?.batch?.name,
                value: selectedRecord?.batch?.id,
              },
            }}
            form={form}
          />
        );
      // if (show === "batch")
      //   return (
      //     <AddEditBatch
      //       data={{
      //         ...editData,
      //         department: {
      //           label: selectedRecord?.department?.name,
      //           value: selectedRecord?.department?.id,
      //         },
      //         course: {
      //           label: selectedRecord?.course?.name,
      //           value: selectedRecord?.course?.id,
      //         },
      //         schema: {
      //           label: selectedRecord?.schema?.name,
      //           value: selectedRecord?.schema?.id,
      //         },
      //         semester: {
      //           label: selectedRecord?.semester?.name,
      //           value: selectedRecord?.semester?.id,
      //         },
      //       }}
      //       form={form}
      //     />
      //   );
  };

  const getTitle = (): string => {
    let str: string = "";
    if (show === "department") str = "Department";
    if (show === "course") str = "Course";
    if (show === "schema") str = "Schema";
    if (show === "semester") str = "Semester";
    if (show === "subject") str = "Subject";
    if (show === "section") str = "Section";
    if (show === "batch") str="Batch";

    return str;
  };

  const saveData = async (data: any) => {
    form.validateFields().then(async (res: any) => {

      let data = {
        ...res,
        id: editData?.id,
        masterType: getMasterType(),
      };
      if (!!res?.department) {
        data = {
          ...data,
          department: {
            id: res?.department?.value,
            name: res?.department?.label,
          },
        };
      }
      if (!!res?.course) {
        data = {
          ...data,
          course: {
            id: res?.course?.value,
            name: res?.course?.label,
          },
        };
      }
      if (!!res?.schema) {
        data = {
          ...data,
          schema: {
            id: res?.schema?.value,
            name: res?.schema?.label,
          },
        };
      }

      if (!!res?.batch) {
        data = {
          ...data,
          batch: {
            id: res?.batch?.value,
            name: res?.batch?.label,
          },
        };
      }
      // if (!!res?.section) {
      //   data = {
      //     ...data,
      //     section: res?.section?.map((itm:any)=>({
      //       id: itm?.value,
      //       name: itm?.label
      //     }))

      //     }
      //   }

      if (!!res?.semester) {
        data = {
          ...data,
          semester: {
            id: res?.semester?.value,
            name: res?.semester?.label,
          },
        };
      }
      if (!!res?.department && !!res?.course && !!res?.schema) {
        let weekdays = [
          { day: 1, isChecked: batchDays.sun },
          { day: 2, isChecked: batchDays.mon },
          { day: 3, isChecked: batchDays.tue },
          { day: 4, isChecked: batchDays.wed },
          { day: 5, isChecked: batchDays.thu },
          { day: 6, isChecked: batchDays.fri },
          { day: 7, isChecked: batchDays.sat },
        ];
        let checkDays = weekdays.some((item: any) => item.isChecked === true);
        if (checkDays) {
          data = {
            ...data,
            batchDays:weekdays,
            batch: {
              id: res?.batch?.value,
              name: res?.batch?.label,
            },
            punchInTime: res?.punchInTime?.format("hh:mm A"),
            punchOutTime: res?.punchOutTime?.format("hh:mm A"),
          };
        }
      }
      if (!!res?.duration && res?.duration?.length >= 2) {
        data = {
          ...data,
          duration: {
            from: res?.duration[0],
            to: res?.duration[1],
          },
        };
      }
      if (!!res?.subjectTypes) {
        data = {
          ...data,
          subjectTypes: {
            id: res?.subjectTypes?.value,
            name: res?.subjectTypes?.label
          }
        }
      }
  
      setLoading(true);
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_MASTER,
        {},
        data
      );
      if (response.message || response.errors) {
        setLoading(false);
        message.error(response.message);
      } else {
        setLoading(false);
        message.success("Submitted Successfully");
        closeDrawer(true);
        setRefresh(!refresh);
      }
    });
  };
  const getMasterType = () => {
    if (show === "department") return MasterTypes.Department;
    if (show === "course") return MasterTypes.Course;
    if (show === "schema") return MasterTypes.Schema;
    if (show === "semester") return MasterTypes.Semester;
    if (show === "subject") return MasterTypes.Subjects;
    if (show === "batch") return MasterTypes.Batch;
    if (show === "section") return MasterTypes.Section;
    // if (show === "section") return MasterTypes.Section;
  };
  const closeDrawer = (isChange: boolean) => {
    setShow("");
    form.resetFields();
    // if (!isChange)
    setEditData(undefined)
    setBatchDays({
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    }) 
  };

  const getDetails = async () => {
    setApiDataLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS,
      {},
      {
        departmentId: selectedRecord?.department?.id,
        courseId: selectedRecord?.course?.id,
        schemaId: selectedRecord?.schema?.id,
        semesterId: selectedRecord?.semester?.id,
        batchId:selectedRecord?.batch?.id
        // sectionId: selectedRecord?.section?.id
      }
    );

    if (!res?.message) {
      let result = res?.result;
      setSelectedRecord((pre: any) => ({
        ...pre,
        department: Array.isArray(result?.departments)
          ? getSelectedId(result?.departments, selectedRecord?.department)
          : undefined,
        course: Array.isArray(result?.courses)
          ? getSelectedId(result?.courses, selectedRecord?.course)
          : undefined,
        schema: Array.isArray(result?.schemas)
          ? getSelectedId(result?.schemas, selectedRecord?.schema)
          : undefined,
        semester: Array.isArray(result?.semesters)
          ? getSelectedId(result?.semesters, selectedRecord?.semester)
          : undefined,
          batch: Array.isArray(result?.batch)
          ? getSelectedId(result?.batch, selectedRecord?.batch)
          : undefined,
        // section: Array.isArray(result?.section)
        //   ? getSelectedId(result?.section, selectedRecord?.section)
        //   : undefined
      }));
      setDetails({
        departments: result?.departments?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        courses: result?.courses?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        schemas: result?.schemas?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        semesters: result?.semesters?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        subjects: result?.subjects?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        batch: result?.batch?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        section:result?.section?.map((itm:any)=>({
          ...itm,
          key:itm?.id
        }))
      });
      setApiDataLoading(false);
    } else {
      message.error(res?.message);
      setApiDataLoading(false);
    }
  };
  const getSelectedId = (a: any, b: any) => {
    let result: any = undefined
    if (editData && editData?.id === b?.id) {
      result = a?.find((x: any) => x?.id === editData?.id)
    }
    else
      result = b ? b : a[0];
    return result

  };
  useEffect(() => {
    getDetails();
  }, [refresh]);

  const departmentRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.department?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          department: record[0],
          course: undefined,
          schema: undefined,
          semester: undefined,
          batch:undefined
          // section:undefined
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);
  const courseRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.course?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          course: record[0],
          schema: undefined,
          semester: undefined,
          batch:undefined,
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);

  const schemaRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.schema?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          schema: record[0],
          semester: undefined,
          batch:undefined
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);
  const semesterRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.semester?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          semester: record[0],
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);
  const batchRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.batch?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          batch: record[0],
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);

  const isEmpty = useCallback((source: any) => {
    if (Array.isArray(source)) return source.length <= 0
    return !source ? true : false
  }, [])

  const getSchemaData = () => {

    if (editData) {
      let val = {
        ...editData
      }
      if (editData?.duration && editData?.duration?.from && editData?.duration?.to)
        val = {
          ...val,
          duration: [dayjs(editData?.duration?.form), dayjs(editData?.duration?.to)],
        }
      return val
    }
    return {}

  }

  return (
    <>
      <Spin
        spinning={apiDataLoading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <ConfigProvider theme={{ components: { Table: { borderRadius: 0, headerBorderRadius: 0, fontSize: 13 } } }}>
          <Row gutter={[18,18]}>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Department"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("department");
                    }}
                  >
                    Add
                  </Button>
                }
              >

                <Table
                 className="masterList"
                  key={apiDataLoading ? "departmentLoading" : "Department"}
                  rowSelection={departmentRowSelection}
                  columns={departmentColumns}
                  dataSource={details?.departments}
                  size="small"
                  pagination={false}
                  scroll={{y:300}}
                  
           
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Course"
                size="small"
                className="tableCard"


                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("course");
                    }}
                    disabled={isEmpty(details?.departments)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                 className="masterList"
                  key={apiDataLoading ? "courseLoading" : "Course"}
                  rowSelection={courseRowSelection}
                  columns={courseColumns}
                  dataSource={details?.courses}
                  size="small"
                  pagination={false} 
                  scroll={{y:300}}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Schemas"
                size="small"
                className="tableCard"
                onClick={() => {
                  setShow("schema");
                }}
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    disabled={isEmpty(details?.courses)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                 className="masterList"
                  key={apiDataLoading ? "schemaLoading" : "Schema"}
                  rowSelection={schemaRowSelection}
                  columns={schemaColumns}
                  dataSource={details?.schemas}
                  size="small"
                  scroll={{y:300}}
                  pagination={false}
                />
              </Card>
            </Col>
          {/* </Row>
          <Row gutter={[5, 24]} style={{ marginTop: 5 }}> */}
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Batch / Session"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("batch");
                    }}
                    disabled={isEmpty(details?.schemas)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                 className="masterList"
                 scroll={{y:300}}
                  key={apiDataLoading ? "subjectLoading" : "Subject"}
                  rowSelection={batchRowSelection}
                  columns={batchColumns}
                  dataSource={details?.batch}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Semesters"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("semester");
                    }}
                    disabled={isEmpty(details?.schemas)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                 className="masterList"
                 scroll={{y:300}}
                  key={apiDataLoading ? "semesterLoading" : "Semester"}
                  rowSelection={semesterRowSelection}
                  columns={semesterColumns}
                  dataSource={details?.semesters}
                  size="small"   
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Section"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("section");
                    }}
                    disabled={isEmpty(details?.semesters)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                scroll={{y:300}}
                 className="masterList"
                  key={apiDataLoading ? "subjectLoading" : "Subject"}
                  columns={sectionColumns}
                  dataSource={details?.section}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                 style={{height:"400px"}}
                title="Subjects"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("subject");
                    }}
                    disabled={isEmpty(details?.semesters)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                scroll={{y:300}}
                 className="masterList"
                  key={apiDataLoading ? "subjectLoading" : "Subject"}
                  columns={subjectColumns}
                  dataSource={details?.subjects}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </ConfigProvider>

        <Drawer
          placement="right"
          width={getTitle() === "Subject" ? "55%" : 600}
          maskClosable={false}
          closeIcon={false}
          extra={
            <CloseOutlined
              style={{ fontSize: "16px" }}
              className=""
              onClick={() => closeDrawer(false)}
            />
          }
          footer={
            <Row justify="end">
              <Button
                onClick={() => closeDrawer(false)}
                disabled={loading}
                className="ttp-btn-light btn-m-sm"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="btn-m-sm"
                loading={loading}
                onClick={() => saveData(postData)}
              >
                {/* {department ? "Update" : "Save"} */}
                Save
              </Button>
            </Row>
          }
          title={getTitle()}
          onClose={() => {
            setShow("");
          }}
          open={!!show}
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{ height: "100%" }}
          >
            {getComponent()}
          </Spin>
        </Drawer>
      </Spin>
    </>
  );
};

export default DesignCourse;
