import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Col, Button, Drawer, Form, Select, TimePicker, Spin, DatePicker, Popconfirm } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, DeleteOutlined, LoadingOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { adminContext } from '../../../Common/PageRoute';
import AttendanceService from '../../../Services/AttendanceService';
const { Option } = Select;

const AddEditAttendance: React.FC<{ stuId: string | undefined, attDate: string | undefined, attId:any ,drawerOpen: boolean, setDrawerClose: Function, setTrigger: Function }> = (props) => {

    interface IAttendance {
        id: string
        key: number;
        sno: number;
        attDate: string
        attId: string;
        dayTrans: IDayTrans[];
        isHoliday: boolean;
        isAbsent: boolean;
        isPresent: boolean;
        studyMinute: number;
        requiredMinute: number;
        in: Date;
        out: Date;
        inDiff: number;
        outDiff: number
        abbriviation: string;
        holidayName: string;
        remark: string
    };
    interface IDayTrans {
        time: string;
        isManual: boolean;
    };

    const { openNotification } = React.useContext(adminContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isAbsent, setIsAbsent] = useState(false);
    const [isPunchtime, setIsPunchtime] = useState(false);
    const [batchDetail, setBatchDetail] = useState<any>();
    const [attendance, setAttendance] = useState<IAttendance>();
    const [studentList, setStudentList] = useState<any>();
    const [stuId, setstuId] = useState<string>();
    const [attDate, setAttDate] = useState<string>();


    useEffect(() => {
        if (props.drawerOpen) {
            setLoading(true);
            getAllStudentList();
            setstuId(props?.stuId);
            setAttDate(dayjs(props?.attDate).format("MM-DD-YYYY"));
            form.setFieldsValue({ stuId: props?.stuId, attDate: dayjs(props?.attDate) });
        }
    }, [props.drawerOpen]);

    useEffect(() => {
        if (props.drawerOpen) {
            form.resetFields(["remark", "dayTrans"]);
            if (attendance?.id) {
                // for absent
                if (attendance?.isAbsent) {
                    form.setFieldsValue({ attStatus: "Absent" });
                    setIsAbsent(true);
                    setIsPunchtime(false);
                }

                // for punch time 
                else if (attendance?.dayTrans?.length > 0) {
                    setIsPunchtime(true);
                    setIsAbsent(false);
                    form.setFieldsValue({
                        attStatus: "punchtime",
                        dayTrans: attendance?.dayTrans?.map((item: any) => ({
                            time: dayjs(dayjs.utc(item?.time).format("DD/MM/YYYY hh:mm:ss A"), "DD/MM/YYYY hh:mm:ss A"),
                            isManual: item?.isManual,
                        }))
                    });
                }

                else if (attendance?.isPresent) {
                    form.setFieldsValue({ attStatus: "present" });
                    setIsPunchtime(false);
                    setIsAbsent(false);
                }

                // for holiday
                if (attendance?.isHoliday && attendance?.dayTrans) {
                    setIsPunchtime(true);
                    setIsAbsent(false);
                    form.setFieldsValue({
                        attStatus: "punchtime",
                        dayTrans: attendance?.dayTrans?.map((item: any) => ({
                            time: dayjs(dayjs.utc(item?.time).format("DD/MM/YYYY hh:mm:ss A"), "DD/MM/YYYY hh:mm:ss A"),
                            isManual: item?.isManual,
                            userName: item?.createdBy?.name
                        }))
                    });
                }
                form.setFieldsValue({ remark: attendance?.remark });
            }
            else {
                setIsPunchtime(true);
                setIsAbsent(false);
                form.setFieldsValue({ attStatus: "punchtime" });
            }
        }
    }, [attendance]);

    useEffect(() => {
        if (props.drawerOpen && stuId && attDate ) {
            setLoading(true);
            getAttendance(stuId, attDate,props?.attId);
        }
    }, [stuId, attDate,props?.attId]);

    const getAllStudentList = async () => {
        await AttendanceService.get_all_student_list(dayjs(props?.attDate).format("YYYY"), dayjs(props?.attDate).format("MM"), "").then((res) => {
            if (res?.length > 0) {
                setStudentList(res.map((r: any) => ({
                    value: r?.id,
                    label: r?.name,
                })));
            }
            else {
                setStudentList(undefined);

            }
        });
    }
    const getAttendance = async (stuId: string | undefined, attDate: string | undefined,attId?:any) => {
        if (attId==="") {
            await AttendanceService.get_Att_By_Id_Absent(stuId, attDate,attId).then((res) => {
                if (res) {
                    setAttendance(res?.attendance);
                    setBatchDetail(res?.batchDetail);
                }
                setLoading(false);
            });
        }else{
                await AttendanceService.get_Att_By_Id(stuId, attDate,attId).then((res) => {
                    if (res) {
                        setAttendance(res?.attendance);
                        setBatchDetail(res?.batchDetail);
                    }
                    setLoading(false);
                });
        }
    };


    const onClose = () => {
        props.setDrawerClose(false);
        setBtnLoading(false);
        setIsAbsent(false);
        setIsPunchtime(false);
        setBatchDetail(undefined);
        form.resetFields();
        setstuId(undefined);
        setAttDate(undefined);
        setAttendance(undefined);
    }


    const saveAttendance = () => {
        form.validateFields().then(async (val) => {
            setBtnLoading(true);
            let att = { ...val, attId: attendance?.id, attDate: dayjs(val.attDate).format("MM-DD-YYYY") }
            await AttendanceService.save_attendance(att).then((res) => {
                if (res.status) {
                    onClose();
                    props.setTrigger((x: any) => !x);
                    openNotification('success', attendance?.id ? 'Attendance Updated successfully' : 'Attendance Saved successfully');
                    setBtnLoading(false);
                } else {
                    openNotification('error', res.message);
                    getAttendance(stuId, dayjs(attDate).format("MM-DD-YYYY"));
                    setBtnLoading(false);
                }
            });
        }).catch(() => { return });
    }

    const handleAttStatus = (val: any) => {
        form.resetFields(["leaveId", "dayType", "halfDayType"]);
        if (val === "present") {
            setIsAbsent(false);
            setIsPunchtime(false);
        }
        else if (val === "absent") {
            setIsAbsent(true);
            setIsPunchtime(false);
        }
        else {
            setIsPunchtime(true);
            setIsAbsent(false);
        }
    };
    const saveDayTrans = async (time: any, timeString: any) => {
        setLoading(true);
        let att = { attId: attendance?.id, attDate: dayjs(attDate).format("MM-DD-YYYY"), time: timeString }
        await AttendanceService.save_Punch_Time(stuId, att).then((res) => {
            if (res.status) {
                setLoading(false);
                setAttendance(res?.result);
                props.setTrigger((x: any) => !x);
                openNotification('success', 'Punch time added successfully');
            } else {
                openNotification('error', res.message);
                getAttendance(stuId, dayjs(attDate).format("MM-DD-YYYY"));
                setLoading(false);
            }
        });
    }

    // delete dayTrans
    const deleteDayTrans = async (index: any) => {
                let att = { attId: attendance?.id, attDate: dayjs(attDate).format("MM-DD-YYYY"), time: dayjs.utc(attendance?.dayTrans[index]?.time).format("hh:mm:ss A") }
                await AttendanceService.delete_Punch_Time(stuId, att).then((res) => {
                    if (res.status) {
                        setAttendance(res?.result);
                        props.setTrigger((x: any) => !x);
                        if (res?.result?.id === null) {
                            getAttendance(stuId, dayjs(attDate).format("MM-DD-YYYY"));
                        }
                        openNotification('success', 'Punch time deleted successfully');
                    } else {
                        openNotification('error', res.message);
                    }
                });
    };


    return (
        <Drawer
            title={<Row align="middle">
                <Col className="fs16">Attendance</Col>
                <Col className="ms-1" style={{ color: "#1890ff" }}>( {studentList?.find((item: any) => item?.value === stuId)?.label} )</Col>
            </Row>}
            placement="right"
            width={500}
            maskClosable={false}
            onClose={onClose}
            closeIcon={false}
            open={props.drawerOpen}
            destroyOnClose
            extra={<CloseOutlined style={{ fontSize: '16px' }} className="cusror" onClick={onClose} />}
            footer={
                <Row justify="end">
                    <Button onClick={onClose} className="ttp-btn-light btn-m-sm" >Cancel</Button>
                    <Button type="primary" loading={btnLoading} className="btn-m-sm" onClick={saveAttendance}>Save</Button>
                </Row>
            }>
            <Spin spinning={loading} indicator={<LoadingOutlined />} style={{ height: "100%" }}>

                <Form
                    form={form}
                    name="edit-att-form"
                    className="DrawerPadding"
                    labelCol={{ xs: 10, sm: 8 }}
                    wrapperCol={{ xs: 14, sm: 15 }}
                    colon={false}
                    autoComplete="off"
                    requiredMark={false}
                    size="small"
                    style={{ height: 500 }}
                >

                    <Row justify="space-between" align="middle" style={{ backgroundColor: '#dee2e638', margin: '0px -20px' }} className="ps-3 py-2 pe-4 fw600">
                        <Col style={{ color: "#1890ff" }} span={8}>{dayjs(attDate).format('DD MMMM')}</Col>
                        <Col span={16} style={{ fontSize: 13, textAlign: 'right' }}>{batchDetail?.name} ( {batchDetail?.punchInTime} - {batchDetail?.punchOutTime} )
                        </Col>
                    </Row>

                    <Form.Item label="Student Name" name="stuId" labelAlign="left" className="mt-4"
                        rules={[{ required: true, message: 'This Field is required' }]}
                    >
                        <Select
                        popupClassName="cit-select-box"
                            placeholder="Select" optionFilterProp="children" showSearch allowClear={false}
                            size='middle'
                            filterOption={(input, option) =>
                                (option?.children?.toString() ?? '')?.toLowerCase().includes(input?.trim().toLowerCase())
                            }
                            className="w100"
                            onChange={(val) => setstuId(val)}
                        >
                            {
                                studentList?.map((itm: any, index: number) =>
                                    <Option key={index} value={itm?.value}>{itm?.label}</Option>
                                )}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Attendance Date" name="attDate" labelAlign="left"
                        rules={[{ required: true, message: "Date is required" }]}
                    >
                        <DatePicker
                            size='middle'
                            format='DD/MM/YYYY' allowClear={false}
                            onChange={(val) => setAttDate(val?.format("MM-DD-YYYY"))}
                            disabledDate={(current) => { return current && current > dayjs().endOf('day'); }}
                            presets={[
                                { label: 'Yesterday', value: dayjs().add(-1, 'd') },
                                { label: 'Last Week', value: dayjs().add(-7, 'd') },
                                { label: 'Last Month', value: dayjs().add(-1, 'month') },
                            ]}
                            className=" w100" />
                    </Form.Item>

                    <Form.Item label="Attendance Status" name="attStatus" labelAlign="left" className="form-item-input mt-3 text-unset"
                        rules={[{ required: true, message: "Attendance Status is required" }]} initialValue="punchtime"
                    >
                        <Select
                            popupClassName="cit-select-box"
                            placeholder="Select" size='middle'
                            className="w100"
                            onChange={handleAttStatus}
                        >
                            <Option value="punchtime" >Punch Time</Option>
                            <Option value="present" >Present</Option>
                            {/* <Option value="absent">Absent</Option> */}
                            {attendance?.id ? <Option value="absent">Absent</Option> : ""}
                        </Select>
                    </Form.Item>

                    <div style={{ maxHeight: 252, overflow: "auto" }}>
                        {isPunchtime ? <Form.List name="dayTrans"
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} className="mb-2" >
                                            <Col xs={10} sm={8}>{index === 0 ? 'Punch Time' : ''}</Col>
                                            <Col xs={14} sm={15}>
                                                <Row align="middle">
                                                    <Col span={2} style={{ paddingTop: 2 }}>
                                                        {index % 2 === 0 ?
                                                            <CaretDownOutlined title="In Time" style={{ color: "#8ce395", fontSize: 15,}} />
                                                            :
                                                            <CaretUpOutlined title="Out Time" style={{ color: "#fa81b4", fontSize: 15 }} />
                                                        }
                                                    </Col>
                                                    <Col span={20}>

                                                        <Form.Item
                                                            wrapperCol={{ span: 24 }}
                                                            name={[field.name, 'time']} labelAlign="left" className=" m-0"
                                                        >

                                                            <TimePicker
                                                                size="middle"
                                                                disabled
                                                                use12Hours
                                                                format="hh:mm:ss A"
                                                                allowClear={false}
                                                                className=" px-1 w100"
                                                            />


                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        {form.getFieldValue(["dayTrans", field.name, "isManual"]) &&
                                                            <Popconfirm
                                                            placement="bottomLeft"
                                                                title="Delete Punch Time"
                                                                description="Are you sure to delete this punch time?"
                                                                onConfirm={()=>deleteDayTrans(field.name)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteOutlined className="cursor deleteIconColour"  style={{ marginLeft:5,fontSize:15}} title="Delete"/>
                                                            </Popconfirm>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List> : <></>}

                    </div>

                    {isPunchtime && <Form.Item
                        label={' '} labelCol={{ xs: 11, sm: 8}} wrapperCol={{ xs: 13, sm: 15 }}
                        labelAlign="left" name="addPunchBtn"
                    >
                        <TimePicker
                            className="cit-add-punchTime mt-2"
                            size="middle" suffixIcon={false}
                            onChange={(time, timeString) => { saveDayTrans(time, timeString); form.resetFields(["addPunchBtn"]); }}
                            use12Hours allowClear={false} format="hh:mm A"
                            placeholder={attendance?.dayTrans && attendance?.dayTrans?.length % 2 ? " + Punch Out" : " + Punch In"}
                            style={{ borderRadius: 6, fontSize: 13}}
                        />

                    </Form.Item>}


                    <Form.Item label="Remark" name="remark" labelAlign="left" 
                        rules={[{ required: isAbsent, message: 'Remark is required' }]}
                    >
                        <TextArea autoSize={{ minRows: 3, maxRows: 4 }}  placeholder='Add Remark' />
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    );
}
export default AddEditAttendance;
