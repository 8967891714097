import "../../Common/MainLayout.css";
import {
  DownloadOutlined,
  FileTextOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Typography,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";

import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  CandidateRequestType,
  MasterTypes,
  StatusOptions,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import "../../Custom.css";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import {
  EnquiryEndPoint,
  FeesTypeMaster,
  PaymentMethodMaster,
} from "../../ApiUrls/URLS";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import FeesSummary from "./FeesSummary";
import AddEditFees from "./AddEditFees";
import { pageSizeOption } from "../../component/Users/UserList";
import Search from "antd/es/input/Search";
import { batch, useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";
import FeesGraph from "./FeesGraph";
import { Utils } from "../../Utilities/Utils";
import "../../Common/MainLayout.css"
import { useNavigate } from "react-router-dom";
import Link from "antd/es/typography/Link";
const { Paragraph } = Typography;

type RequiredMark = boolean | "optional";
interface IdName {
  id: string;
  name: string;
}
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");
const masterApi = new ApiCalls(
  PaymentMethodMaster.endPoints,
  PaymentMethodMaster.prefix
);

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
interface StudentId {
  id: string;
  studentid: string;
}
const Fees = () => {
  const [form] = Form.useForm();
  const [feesFilterObject, setFeesFilterObject] = useState<StudentId>({
    id: "",
    studentid: "",
  });
  const [reset, setReset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [refresh, setRefresh] = useState(false);
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [status,setStatus]=useState<any>('all');
  const [studentDetails, setStudentDetails] = useState<
    { label: string; value: string }[]
  >([]);
  const [paymentDetails, setPaymentDetails] = useState<
    { label: string; value: string }[]
  >([]);
  const [feesTypeOption, setFeesTypeOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [feesData, setFeesData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>({
    student: {},
    // data: [],
  });
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });

  const [listParams, setListParams] = useState({
    streamSearch: "",
    batchSearch: "",
    start: 0,
    length: 15,
    search: "",
    sortCol: "Student.Name",
    sortDir: "ascend",
    status:"all"
  });

  const changeListParams = (key: string, value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [open, setOpen] = useState("");
  const [masterData, setMasterData] = useState<{
    batch: any;
  }>({
    batch: [],
  });
  const [streamData, setStremData] = useState<any[]>([]);
  const [isCSVDownloading, setISCSVDownloading] = useState<boolean>(false);
  const [feesGraphData,setFeesGraphData]=useState<any>([]);
  const [grandFeesGraphData,setGrandFeesGraphData]=useState<any>([]);
  const showDrawer = () => {
    setOpen("showDrawer");
  };
  const onDismiss = () => {
    setOpen("");
    setReset((prev: number) => prev + 1);
  };

  // console.log(feesFilterObject, "feesFilterObject");
  const calculateTotals = () => {
    return feesData.reduce(
      (acc:any, item:any) => {
        acc.feesAmount += item.feesAmount || 0;
        acc.totalPaidAmount += item.totalPaidAmount || 0;
        acc.remainingAmount += item.remainingAmount || 0;
        acc.totalRefundable+=item?.totalRefundable||0;
        acc.totalPaidToParul+=item?.totalPaidToParul||0;
        acc.totalPaidToParulCIT+=item?.totalPaidToParulCIT||0;
        acc.totalCITAmount+=item?.totalCITAmount||0;
        return acc;
      },
      { feesAmount: 0, totalPaidAmount: 0, remainingAmount: 0,totalRefundable:0 ,totalPaidToParul:0, totalPaidToParulCIT:0, totalCITAmount:0}
    );
  };
  const totals=calculateTotals();
  const navigate=useNavigate();
  const columns: ColumnsType<DataType> = [
    {
      title: "S No",
      dataIndex: "sno",
      key: "sno",
      // fixed: "left",
      width: "5%",
      responsive:['xs', 'sm', 'md', 'lg']
    },
    {
      title: "Name",
      dataIndex: "student",
      key: "name",
      // fixed:"left",
      width: "12%",
      ellipsis:true,
      showSorterTooltip:true,
      render: (student: any) => <span className="cursor" onClick={()=>{navigate(`/student/${student?.id}`)}}><Link>{student?.name}</Link></span>,
      responsive:['xs', 'sm', 'md', 'lg']
    },
    {
      title: "Fees Amount",
      dataIndex: "feesAmount",
      key: "amount",
      // filterSearch: true,
      align: "end",
      // width: "200px",
      width: "8%",
      render: (amount: any) => <span>₹{Utils.getFormattedNumber(amount)}</span>,
      responsive:['xs', 'sm', 'md', 'lg'] ,
    },
    {
      title: "Total Received",
      dataIndex: "totalPaidAmount",
      align: "end",
      key: "totalPaidAmount",
      // filterSearch: true,
      // width: "200px",
      width: "8%",
      render: (amount: any) => <span>₹{Utils.getFormattedNumber(amount)}</span>,
      responsive:['xs', 'sm', 'md', 'lg'] ,
    },
    {
      title: "Net Received",
      dataIndex: "netReceivedAmount",
      align: "end",
      key: "netReceivedAmount",
      // filterSearch: true,
      // width: "200px",
      width: "8%",
      render: (amount: any,record:any) => <span>₹{Utils.getFormattedNumber((record?.totalPaidAmount-record?.totalRefundable))}</span>,
      responsive:['xs', 'sm', 'md', 'lg'] ,
    },
    {
      align: "end",
      title: () => "Refunded",
      dataIndex:"totalRefundable",
      key:"totalRefundable",
      width: "8%",
      render:(refund:any)=><span>₹{Utils.getFormattedNumber(refund)}</span>,
      responsive:['xs', 'sm', 'md', 'lg'],
  
    },
    {
      align: "end",
      title: () => "Parul by CIT",
      dataIndex:"totalPaidToParulCIT",
      key:"totalPaidToParulCIT",
      width: "8%",
      render:(amount:any)=><span>₹{Utils.getFormattedNumber(amount)}</span>,
      responsive:['xs', 'sm', 'md', 'lg'],
  
    },
    {
      title: () => "CIT Amount",
      dataIndex: "totalCITAmount",
      align: "end",
      key: "totalCITAmount",
      // filterSearch: true,
      // width: "200px",
      render: (amount: any, record:any) => <span>₹{ Utils.getFormattedNumber(amount)}</span>,
      width: "8%",
      responsive:['xs', 'sm', 'md', 'lg'],
    },
    {
      align: "end",
      title: () => "Parul by Student",
      dataIndex:"totalPaidToParul",
      key:"totalPaidToParul",
      width: "8%",
      render:(refund:any)=><span>₹{Utils.getFormattedNumber(refund)}</span>,
      responsive:['xs', 'sm', 'md', 'lg'],
    },
    {
      align: "end",
      title: () => "Due Amt.",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      // filterSearch: true,
      render: (amount: any) => <span>₹{Utils.getFormattedNumber(amount)}</span>,
      width: "8%",
      responsive:['xs', 'sm', 'md', 'lg'],
    },
    // {
    //   // align: "end",
    //   title: "Stream",
    //   dataIndex: "stream",
    //   key: "stream",
    //   // filterSearch: true,
    //   // width: "200px",
    //   render: (stream: any) => <span>{stream?.name ?? "-"}</span>,
    //   width: "15%",
    //   responsive:['xs', 'sm', 'md', 'lg'],
    // },
    {
      align: "end",
      title: () => "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (batch: any) => <span>{batch?.name ?? "-"}</span>,
      width: "10%",
      responsive:['xs', 'sm', 'md', 'lg'],
    },
    {
      title: " ",
      width: "7%",
      align: "right",
      dataIndex: "paymentDetail",
      // fixed:"right",
      responsive:['xs', 'sm', 'md', 'lg'],
      render: (data: any, record: any) => (
        <>
          {
            <Button
              type="text"
              icon={
                <FileTextOutlined
                  style={{
                    color: "#377dff",
                  }}
                />
              }
              className="cursorPointer"
              style={{ color: "#377dff" }}
              onClick={() => {
                setTableData({ student: record?.student, id: record?.id });
                setOpen("showModal");
              }}
              size="small"
            >
              Summary
            </Button>
          }
        </>
      ),
    },
  ];

  // GET and POST methods for form and table
  const GetStudents = async () => {
    setIsLoading(true);
    let res = await api.GET(EnquiryEndPoint.getList + "?", {
      type: CandidateRequestType.Student,
      length: 100,
    });
    // setPagingItems((p) => {
    //   p.totalRecords = res?.result?.totalRecords;
    //   return p;
    // });
    setStudentDetails(
      res?.result?.items?.map((arrayItems: IdName) => ({
        value: arrayItems?.id,
        label: arrayItems?.name,
      }))
    );
    setIsLoading(false);
  };

  const GetPaymentMethods = async () => {
    let res = await masterApi.GET(PaymentMethodMaster.endPoints.getList + "?", {
      masterType: MasterTypes.PaymentMethod,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    setPaymentDetails(
      res.result?.items?.map((arrayItems: IdName) => ({
        value: arrayItems?.id,
        label: arrayItems?.name,
      }))
    );
  };
  const GetFeesType = async () => {
    let res = await masterApi.GET(FeesTypeMaster.endPoints.getList + "?", {
      masterType: MasterTypes.FeesType,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    setFeesTypeOption(
      res.result?.items?.map((arrayItems: IdName) => ({
        value: arrayItems?.id,
        label: arrayItems?.name,
      }))
    );
  };

  const batchState = useSelector(selectBatchState)
  const getFeesData = async () => {
    setModelLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.PAYMENT_GET_LIST + "?",
      {
        streamSearch: listParams?.streamSearch,
        batchSearch: batchState,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        search:listParams?.search,
        status:status
      }
    );
    // setPagingItems({...pagingItems,pageSize:res?.result?.totalRecords})
    setTotalNoOfRecords(res?.result?.data?.totalRecords);
    setFeesGraphData(res?.result?.data?.total);
    setGrandFeesGraphData(res?.result?.grandTotalFees);
    if (/*res?.errors === null &&*/ res?.result?.data?.items.length >= 0) {
      const result = res?.result?.data?.items;
      setFeesData(() =>
        result?.map((itm: any, index: number) => {
          return {
            sno: itm?.sno,
            key: index,
            student: itm?.student,
            id: itm?.id,
            paymentDetail: itm?.paymentDetail?.map(
              (item: any, innerIndex: number) => ({ ...item, key: innerIndex })
            ),
            totalPaidAmount: itm?.totalPaidAmount,
            remainingAmount: itm?.remainingAmount,
            feesAmount: itm?.feesAmount,
            stream: itm?.stream,
            batch: itm?.batch,
            totalRefundable:itm?.totalRefundable,
            totalPaidToParul:itm?.totalPaidToParul,
            totalCITAmount:itm?.totalCITAmount,
            totalPaidToParulCIT:itm?.totalPaidToParulCIT,
          };
        })
      );
      setPagingItems((p) => {
        p.totalRecords = res?.result?.data?.totalRecords;
        return p;
      });
      setModelLoading(false);
    }
    setModelLoading(false);
  };

  console.log(feesData, "testCase");
  const handleClick = async () => {   
    form.validateFields().then(async (formValues: any) => {
      setIsLoading(true);
      await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.PAYMENT_ADD,
        {},
        {
          ...formValues,
          student: {
            id: formValues?.student?.value,
            name: formValues?.student?.label,
          },
          medium: {
            id: formValues?.medium?.value,
            name: formValues?.medium?.label,
          },
          feesType: {
            id: formValues?.feesType?.value,
            name: formValues?.feesType?.label,
          },
        }
      ).then((res) => {
        if (res?.message) {
          message.error(res?.message);
          setIsLoading(false);
          // onClose();
        } else {
          message.success("Submitted Successfully");
          setIsLoading(false);
          // onClose();
        }
      });
    });
  };

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        batch: any;
      } = {
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
      };
      setMasterData({ ...obj });

      setStremData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items
      );
    } else {
      message.error(res?.message);
    }
  };

  console.log("pagingItems = ",pagingItems);
  

  useEffect(() => {
    GetPaymentMethods();
    GetStudents();
    GetFeesType();
    getMaster();
  }, []);

  useEffect(() => {
    getFeesData();
    // downloadPdf();
  }, [listParams, pagingItems, reset,batchState, status]);

  // useEffect(() => {
  //   feesFilterObject.studentid !== "" && getFeesData();
  // }, [feesFilterObject, reset]);
  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const batch = useSelector(selectBatchState)
  const downloadCSV = async () => {
    setISCSVDownloading(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.FEES_CSV_DOWNLOAD,
      {
        streamSearch: listParams?.streamSearch,
        batchSearch: batch,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        search:listParams?.search,
        status:status
      }
    );
    if (res?.status) {
      setISCSVDownloading(false);
    } else {
      setISCSVDownloading(false);
    }
  };
  return (
    <>
      <Spin fullscreen spinning={isLoading || modelLoading }/>
      <FeesGraph FeesGraphData={grandFeesGraphData}/>
      <Row className="roundedCornerSmall bg-white mt-3" style={{ padding: "16px",height:715 }}>
        <Col span={24}>
          <Row justify="end" gutter={[12, 18]}>
            <Col span={15}>
              <Space.Compact block>
                <Button
                  // type=""
                  onClick={showDrawer}
                  icon={<PlusOutlined />}
                  type="primary"
                  className="cit-add-btn mb-1"
                >
                   Fees
                </Button>
                <Button
                  type="primary"
                  ghost
                  className="cit-add-btn"
                  onClick={() => {
                    downloadCSV();
                  }}
                  icon={<DownloadOutlined />}
                  loading={isCSVDownloading}
                >
                  CSV
                </Button>
              </Space.Compact>{" "}
            </Col>
            <Col span={3}>
            <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val)=>{setStatus(val)}}
                defaultValue={'all'}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={StatusOptions}
              />
            </Col>
            <Col span={3}>
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Stream"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onClear={() => {
                  changeListParams("streamSearch", "");
                }}
                options={streamData}
                onSelect={(val: any) => {
                  changeListParams("streamSearch", val?.value);
                }}
              ></Select>
            </Col>
            {/* <Col span={4}>
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Batch"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                onClear={() => {
                  changeListParams("batchSearch", "");
                }}
                options={masterData?.batch}
                onSelect={(val: any) => {
                  changeListParams("batchSearch", val?.value);
                }}
                filterOption={(input, option:any) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    .includes(input?.trim().toLowerCase())
                }
              ></Select>
            </Col> */}

            <Col span={3}>
            <Search
                  size="middle"
                  placeholder="Search Name..."
                  allowClear
                  className="att-search-input mb-1"
                  onSearch={(val: string) =>
                    changeListParams("search", val)
                  }
                  // onChange={(e: any) =>
                  //   e.target.value === "" ? setListParamsAndRefresh("") : null
                  // }
                  style={{ width: "100%" }}
                />
            </Col>
          </Row>

          <Col span={24} style={{ paddingTop: "10px" }}>
            <Table
              size="small"
              className="responsive-table masterList"
              dataSource={ feesData}
              // loading={tableLoading}
              columns={columns}
              style={{minHeight:600}}
            
              // pagination={{
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              //   onChange: (page, pageSize) => {
              //     changeListParams("start", (page - 1) * pageSize);
              //     changeListParams("length", pageSize);
              //     setRefresh((x) => !x);
              //   },
              //   current: listParams.start / listParams.length + 1,
              //   pageSize: listParams.length,
              //   showSizeChanger: true,
              //   total: totalNoOfRecords,
              //   pageSizeOptions: pageSizeOption,
              //   position: ["bottomRight"],
              // }}

              
              summary={() => {
                return (
                  <Table.Summary fixed >
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                       <b> Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalFeesAmount)}</span></Table.Summary.Cell>
                      <Table.Summary.Cell index={3} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalPaidAmount)}</span></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalPaidAmount -feesGraphData?.totalRefundAmount )}</span></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalRefundAmount)}</span> </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalPaidToParulCIT)}</span> </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalCITAmount)}</span> </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalPaidToParul )}</span> </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} align="end">₹<span style={{fontWeight:600}}>{Utils.getFormattedNumber(feesGraphData?.totalRemainingAmount)}</span></Table.Summary.Cell>
                      <Table.Summary.Cell index={10}></Table.Summary.Cell>
                      <Table.Summary.Cell index={11}></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: pageSizeOption,
              }}
              onChange={(paging, filter, sort: any) => {
                sort?.order &&
                  setListParams({
                    ...listParams,
                    sortDir: sort?.order,
                    sortCol: sort?.columnKey,
                  });
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
              scroll={{ x: 50, y: 550 }}
              //
            />
          </Col>
        </Col>
        {/* </div> */}
      </Row>
      {open === "showModal" && (
        <FeesSummary
          tableData={tableData}
          onDismiss={() => {setOpen(""); setTableData([])}}
        />
      )}
      {open === "showDrawer" && (
        <AddEditFees
          onClose={(res: boolean) => (res ? onDismiss() : setOpen(""))}
        />
      )}
    </>
  );
};

export default Fees;
