import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Form,
  MenuProps,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { isUserAdmin } from "../../Utilities/Utils";
import AddEditJoinDetails from "./AddEditTimeSchedule";
import { useGetTimeScheduleByEnquiryId } from "../../Utilities/tanStackQueries";
import moment from "moment";
import TimeScheduleHistory from "./TimeScheduleHistory";

interface JoinDetailsProps {
  apiData: any;
}

const ScheduleDetails = (props: JoinDetailsProps) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [workingHours, setWorkingHours] = useState<string>("");
  const [recordData, setRecordData] = useState<any>();
  const [overAllHours, setOverAllHours] = useState<string>("");
  const [timeHistoryDrawerOpen, setTimeHistoryDrawerOpen] =
    useState<boolean>(false);
  const [form] = useForm();
  const [batchDays, setBatchDays] = useState<any>({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  const batchDaysData = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const {
    data: enquiryShifts,
    isLoading: enquiryShiftsLoading,
    refetch: enquiryShiftRefetch,
  } = useGetTimeScheduleByEnquiryId(props?.apiData?.id);

  const columnsNonBatch: ColumnsType<any> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.mon}
          disabled={true}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, mon: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.tue}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, tue: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.wed}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, wed: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.thu}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, thu: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.fri}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, fri: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.sat}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sat: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => (
        <Checkbox
          disabled={true}
          checked={batchDays.sun}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sun: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
  ];

  const items: MenuProps["items"] = [
    ...(isUserAdmin()
      ? [
          {
            label: "Edit",
            key: "edit",
            icon: <FormOutlined style={{ color: "#8fa3ba" }} />,
            onClick: () => {
              setRecordData(enquiryShifts);
              setOpenDrawer(true);
            },
          },
        ]
      : []),
    {
      label: "History",
      key: "history",
      icon: <HistoryOutlined style={{ color: "#8fa3ba" }} />,
      onClick: () => {
        setTimeHistoryDrawerOpen(true);
      },
    },
  ];

  const calculateWorkingHours = (
    punchInTime: any,
    punchOutTime: any,
    lunchMinutes: number = 0
  ) => {
    if (punchInTime && punchOutTime) {
      const duration = moment.duration(punchOutTime.diff(punchInTime));
      let totalMinutes = duration.asMinutes() - lunchMinutes;
      if (totalMinutes < 0) totalMinutes = 0;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} hrs ${minutes}`;
    }
    return "";
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    const { punchInTime, punchOutTime, lunch } = allValues;
    console.log("punch", punchInTime, punchOutTime);
    if (punchInTime && punchOutTime) {
      const overallHours = calculateWorkingHours(punchInTime, punchOutTime);
      const workingHours = calculateWorkingHours(
        punchInTime,
        punchOutTime,
        lunch
      );
      setOverAllHours(overallHours);
      setWorkingHours(workingHours);
    } else {
      setOverAllHours("");
    }
  };

  useEffect(() => {
    if (enquiryShifts) {
      setBatchDays({
        sun: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 1 && d?.isChecked;
        }),
        mon: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 2 && d?.isChecked;
        }),
        tue: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 3 && d?.isChecked;
        }),
        wed: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 4 && d?.isChecked;
        }),
        thu: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 5 && d?.isChecked;
        }),
        fri: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 6 && d?.isChecked;
        }),
        sat: enquiryShifts?.batchDays?.some((d: any) => {
          return d?.day === 7 && d?.isChecked;
        }),
      });
      handleFormChange(
        {},
        {
          punchInTime: dayjs(enquiryShifts?.punchInTime, "HH:mm"),
          punchOutTime: dayjs(enquiryShifts?.punchOutTime, "HH:mm"),
          lunch: enquiryShifts?.lunchTime,
        }
      );
    }
  }, [enquiryShifts]);

  return (
    <>
      <Row gutter={18}>
        <Col span={12}>
          <Card
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              border: "1px solid #dee2e6",
              minHeight:550
            }}
            title={
              <Row>
                <Col span={20}> Join Details</Col>
                <Col span={4} style={{ textAlign: "end" }}>
                  <BsThreeDotsVertical className="cursor" />
                </Col>
              </Row>
            }
          >
            <Space size={70}>
              <ul style={{ listStyleType: "none", lineHeight: 3 }}>
                <li>Enquiry Date</li>
                <li>Enrolled Date</li>
              </ul>
              <ul style={{ listStyleType: "none", lineHeight: 3 }}>
                <li>
                  {dayjs(props?.apiData?.enquiryDate).format("DD/MM/YYYY")}
                </li>
                <li>
                  {dayjs(props?.apiData?.enrolledDate).format("DD/MM/YYYY")}
                </li>
              </ul>
            </Space>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            loading={enquiryShiftsLoading}
            title={
              <Row justify={"space-between"}>
                <Col>
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                  >
                    Time Schedule
                  </Button>
                </Col>
                {enquiryShifts?.id && (
                  <Col>
                    <Dropdown.Button
                      icon={
                        <MoreOutlined
                          className="more-icon"
                          style={{ color: "#000000c9" }}
                        />
                      }
                      overlayClassName="dropdown-btn-model"
                      menu={{ items }}
                      className="custom-dropdown-btn"
                    ></Dropdown.Button>
                  </Col>
                )}
              </Row>
            }
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              border: "1px solid #dee2e6",
              minHeight:550
            }}
          >
            {enquiryShifts?.id ? (
              <>
                <Row className="mb-3">
                  <Col span={10}>From Date</Col>
                  <Col span={14}>
                    {dayjs(enquiryShifts?.fromDate).format("DD/MM/YYYY")}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col span={10}>Shift</Col>
                  <Col span={14}>{enquiryShifts?.shift?.name || "Custom"}</Col>
                </Row>

                <Row className="mb-3">
                  <Col span={10}>Lunch Minutes</Col>
                  <Col span={14}>
                    <span>{enquiryShifts?.lunchTime}</span>
                    <Checkbox
                      disabled
                      className="ms-2"
                      checked={enquiryShifts?.isLunchCal}
                    >
                      Calculation in Lunch Min
                    </Checkbox>
                    <Tooltip
                      color="#e6f4ff"
                      placement="topLeft"
                      overlayInnerStyle={{ fontSize: 13, color: "black" }}
                      title={
                        <Row>
                          <Col span={24} className="fs13">
                            {" "}
                            <CheckOutlined
                              style={{ fontSize: 13, color: "green" }}
                              className="me-2"
                            />
                            Tick checkbox (The lunch break duration will be
                            deducted, regardless of whether they use it or not.)
                          </Col>
                          <Col span={24} className="fs13">
                            {" "}
                            <CloseOutlined
                              style={{ fontSize: 13, color: "red" }}
                              className="me-2"
                            />
                            Untick checkbox (Deduct only the time that employees
                            actually spend on their lunch break.)
                          </Col>
                        </Row>
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "#a5abdd" }}
                        className="cursor"
                      />
                    </Tooltip>
                  </Col>
                </Row>

                <Typography.Title
                  style={{ fontSize: "14px" }}
                  className="mt-3"
                  level={5}
                >
                  Work Days
                </Typography.Title>

                <Col span={24} className="mt-1 ">
                  <Table
                    bordered={true}
                    columns={columnsNonBatch}
                    pagination={{ hideOnSinglePage: true }}
                    dataSource={batchDaysData}
                    size={"small"}
                    scroll={{ x: 400 }}
                  />
                </Col>
                <Row className="mt-2">
                  <Col xs={24} md={12} xl={12}>
                    <Row justify="space-between" className="my-2">
                      <Col>Punch In</Col>
                      <Col span={14}>
                        {dayjs(enquiryShifts.punchInTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12} xl={12}>
                    <Row justify="space-between" className="my-2">
                      <Col>Punch Out</Col>
                      <Col span={14}>
                        {dayjs(enquiryShifts.punchOutTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row
                  style={{ background: "#dee2e638" }}
                  className="px-3 py-2 mb-3 mt-3 roundedCornerSmall"
                >
                  <Col className="fw600 mb-2">
                    Time Schedule Summary <HistoryOutlined />
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>
                        <Row className="mb-1 color" justify="center">
                          {" "}
                          {workingHours} Mins
                        </Row>
                        <Row className="mb-1 fw500" justify="center">
                          Working Hours
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row className="mb-1 color" justify="center">
                          {" "}
                          {enquiryShifts?.lunchTime} Mins
                        </Row>
                        <Row className="mb-1 fw500" justify="center">
                          Lunch Minutes
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row className="mb-1 color" justify="center">
                          {" "}
                          {overAllHours} Mins
                        </Row>
                        <Row className="mb-1 fw500" justify="center">
                          Overall Hours
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>

      <AddEditJoinDetails
        enquiryIdName={{
          id: props?.apiData?.id,
          name: props?.apiData?.fullName,
        }}
        recordData={recordData}
        setRecordData={setRecordData}
        openDrawer={openDrawer}
        SetDrawerClose={setOpenDrawer}
      />

        <TimeScheduleHistory
          enquiry={{
            name: props?.apiData?.fullName,
            id: props?.apiData?.id,
          }}
          timeHistoryDrawerOpen={timeHistoryDrawerOpen}
          setTimeHistoryDrawerClose={setTimeHistoryDrawerOpen}
        />

    </>
  );
};

export default ScheduleDetails;
